import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material/';
import { useSnackbar, VariantType } from "notistack";
import { ChangeEvent, useEffect, useState } from 'react';
import { DEFAULT_ENVIRONMENTAL_FACTOR } from "../../utils/types/environmentalFactor.types";
import {
    deleteEnvironmentalFactor,
    getEnvironmentalFactor, postEnvironmentalFactor,
    putEnvironmentalFactor
} from "../../utils/helpers/environmentalFactorsHelper";
import { EnvironmentalFactor } from "../../utils/types/environmentalFactor.types";
import FormLabel from "../utils/FormLabel";
import SubmitButton from "../utils/SubmitButton";
import Toolbar from "../utils/Toolbar";

function FactorDetails(props: { factor: EnvironmentalFactor; updateFactors }) {
    
    // Default factor values
    const [factor, setFactor] = useState<EnvironmentalFactor>(props.factor);

    // Function to update default values
    const updateFactorData = (e: ChangeEvent<HTMLInputElement>) => {
        let data = { ...factor };
        data[e.target.name] = e.target.value;
        setFactor(data);
    };

    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    // Setting file path
    async function fileUpload(selectorFiles: FileList | null) {
        if (selectorFiles === null) return;
        let data = { ...factor };
        const reader = new FileReader();
        reader.readAsDataURL(selectorFiles[0]);
        reader.onload = () => {
            data.image.image = reader.result.toString();
            setFactor(data);
        }
    }

    // Adds new factor by making a POST api request
    function addFactor() {
        postEnvironmentalFactor(factor).then((res) => {
            handleClickVariant("Environmental factor successfully added.", 'success');
            props.updateFactors(res.id);
        }).catch(function (error) {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function editFactor () {
        putEnvironmentalFactor(props.factor.id, factor).then((res) => {
            handleClickVariant("Environmental factor successfully saved.", 'success');
            props.updateFactors(res.id);
        }).catch(function (error) {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function saveFactor() {
        if (props.factor.id !== null) {
            editFactor();
        } else {
            addFactor();
        }
    }

    function getFactor( factorId: number ){
        getEnvironmentalFactor( props.factor.id ).then((factorData) => {
            setFactor(factorData);
        }).catch(function (error) {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function deleteFactor() {
        if (props.factor.id !== null) {
            deleteEnvironmentalFactor( props.factor.id ).then(() => {
                handleClickVariant("Environmental factor successfully deleted.", 'success');
                props.updateFactors(0);
            }).catch((error) => {
                handleClickVariant(error.response.data.message, 'error');
            });
        } else {
            handleClickVariant("Environmental factor successfully deleted.", 'success');
            props.updateFactors(0);
        }
    }

    // converts for discrete because React doesn't like booleans
    const str2bool = value => {
        if (value && typeof value === 'string') {
            if (value.toLowerCase() === 'true') return true;
            if (value.toLowerCase() === 'false') return false;
        }
        return value;
    };

    // factor type update
    const updateFactorDataRadioButton = (e: ChangeEvent<HTMLInputElement>) => {
        let data = { ...factor };
        data[e.target.name] = str2bool(e.target.value);
        setFactor(data);
    };

    // use effect to ensure correct factor is displayed or reset to default if add is added
    useEffect(() => {
        if ( props.factor.id === null ) {
            setFactor(DEFAULT_ENVIRONMENTAL_FACTOR());
        } else {
            getFactor(props.factor.id);
        }
    }, [props.factor.id]);

    return (
        <Box sx={{flexBasis: '600px', p: 2}}>
            <FormLabel id={props.factor.id} message={'Environmental Factor'} />
            <FormControl sx={{width: '100%'}}>
                <Box sx={{display: 'flex', mb: 2}}>
                    <Box className="flexColumn" component={"span"} sx={{width: '100%'}}>
                        <TextField required label='Name' variant='standard' name='name' onChange={updateFactorData}
                                   value={factor.name}/>
                        <TextField label='Description' variant='standard' name='description' onChange={updateFactorData}
                                   value={factor.description}/>
                    </Box>
                    <Toolbar id={props.factor.id} message={'Deletion is permanent and cannot be undone'} edit={saveFactor} delete={deleteFactor} />
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                    <Box className="flexColumn" component={"span"} sx={{width: '50%'}}>
                        <TextField required label='Unit' variant='standard' name='unit' onChange={updateFactorData} value={factor.unit} />
                    </Box>
                    <Box className="flexColumn">
                        { factor.image.image !== null &&
                            <Box sx={{ border: '1px dashed grey', maxWidth: '80px', maxHeight: '80px', mb: 2 }} component='img' src={factor.image.image} />
                        }
                        <Button variant='contained' component='label' size='small'>
                                <Typography sx={{ fontWeight: '500', fontSize: 10 }}>Upload Image</Typography>
                                <input type='file' accept=".png,.jpg,.jpeg" onChange={e => fileUpload(e.target.files)} hidden />
                        </Button>
                    </Box>
                </Box>
                {/* Min / max values for each environmental factor. A created tag cannot have more than the max or less than the min. */}
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 2, gap: '5%'}}>
                        <TextField required label='Min Value' variant='standard' name='minValue'
                                   type="number" onChange={updateFactorData} value={factor.minValue}/>
                        <TextField required label='Max Value' variant='standard' name='maxValue'
                                   type="number" onChange={updateFactorData} value={factor.maxValue}/>
                    </Box>
                <SubmitButton id={props.factor.id} submitHandler={addFactor} message={'Submit New Factor'} />
            </FormControl>
        </Box>
    );
}

export default FactorDetails;
