import {SelectChangeEvent, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import { FactorStateTrigger } from '../../utils/types/molecule.types';
import { DEFAULT_RANGE_CONDITION } from '../../utils/types/environmentalFactor.types';
import {ChangeEvent, useEffect, useState} from "react";
import "../../styles/Molecule.css";


function EnvironmentalFactorTrigger(props: {id: number, factorTrigger: FactorStateTrigger, updateState, environmentalFactorList, deleteSelf}) {
    const [rangeConditions, setRangeConditions] = useState(props.factorTrigger.rangeConditions);
    // Sync the local state whenever props.factorTrigger changes
    useEffect(() => {
        setRangeConditions(props.factorTrigger.rangeConditions);
    }, [props.factorTrigger]);

    const environmentalFactorSelected = (e: SelectChangeEvent<any>, id: number) => {
        let newConditions = [...rangeConditions];  
        const selectedFactor = props.environmentalFactorList.find((factor: any) => factor.id === e.target.value);
        newConditions[id] = { ...newConditions[id], factor: selectedFactor };
        setRangeConditions(newConditions);  // Update local state
        props.updateState(props.id, { ...props.factorTrigger, rangeConditions: newConditions });  // Update parent state
    };
    

    const rangeConditionSelected = (e: SelectChangeEvent<any>, id: number) => {
        let newConditions = [...rangeConditions];
        newConditions[id] = {...newConditions[id], type: e.target.value};
        setRangeConditions(newConditions);
        props.updateState(props.id, { ...props.factorTrigger, rangeConditions: newConditions });
    }

    const rangeValueEntered = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
        let newConditions = [...rangeConditions];
        newConditions[id] = { ...newConditions[id], [e.target.name]: e.target.value };
        setRangeConditions(newConditions);
        props.updateState(props.id, { ...props.factorTrigger, rangeConditions: newConditions });
    }

    const addCondition = () => {
        const newCondition = DEFAULT_RANGE_CONDITION();
        const newConditions = [...rangeConditions, newCondition];
        setRangeConditions(newConditions); // set local state
        props.updateState(props.id, { ...props.factorTrigger, rangeConditions: newConditions }); // set parent state
    }

    const removeCondition = (index: number) => {
        const newConditions = rangeConditions.filter((_, i) => i !== index);
        setRangeConditions(newConditions);
        props.updateState(props.id, { ...props.factorTrigger, rangeConditions: newConditions });
        if (newConditions.length === 0) {
            props.deleteSelf(props.id);
        }
    };

    return (
        <Box>
            {rangeConditions.map((rangeCondition: any, index: number) => (
                <div key={rangeCondition.id}>
                    {index > 0 && (
                        <Typography sx={{ fontWeight: '500', fontSize: 16, mb: 1 }}>
                            And
                        </Typography>
                    )}
                    <Box key={rangeCondition.id} className="factorRange" sx={{ mb: 2 }}>
                        {/* Select the factor of interest */}
                        <FormControl className="factorRangeItem" sx={{ width: '75%' }}>
                            <InputLabel id={`env-factor-selector-${index}`} variant="standard">
                                Environmental Factor
                            </InputLabel>

                            <Select
                                labelId={`env-factor-selector-${index}`}
                                variant="standard"
                                id={`env-factor-selector-${index}`}
                                label="Environmental Factor"
                                size="medium"
                                onChange={(e) => environmentalFactorSelected(e, index)} // Pass index
                                value={rangeCondition.factor?.id || ''}  // Use the factor ID as the value
                            >
                                {props.environmentalFactorList.map((environmentalFactor: any) => (
                                    <MenuItem key={environmentalFactor.id} value={environmentalFactor.id}>
                                        {environmentalFactor.name}  {/* Display the name */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <Typography className="factorRangeItem" sx={{ textAlign: 'center', width: '25%' }}>is</Typography>

                        {/* Select the range condition of interest */}
                        <FormControl className="factorRangeItem" sx={{ width: '100%' }}>
                            <InputLabel id={`range-condition-selector-${index}`} variant="standard">Range</InputLabel>
                            <Select
                                labelId={`range-condition-selector-${index}`}
                                variant="standard"
                                id={`range-condition-selector-${index}`}
                                size="medium"
                                label="Range"
                                onChange={(e) => rangeConditionSelected(e, index)} // Pass index
                                value={rangeCondition.type ?? ''}
                            >
                                <MenuItem value="Greater Than">Greater Than</MenuItem>
                                <MenuItem value="Less Than">Less Than</MenuItem>
                                <MenuItem value="Between">Between</MenuItem>
                            </Select>
                        </FormControl>

                        {/* Conditionally render the value input fields */}
                        {rangeCondition.type === "Between" && (
                            <Box className="factorRange">
                                <TextField
                                    className="factorRangeItem"
                                    label="Min"
                                    variant="standard"
                                    name="minValue"
                                    onChange={(e) => rangeValueEntered(e, index)} // Pass index and value type
                                    type="number"
                                    value={rangeCondition.minValue}
                                />
                                <Typography className="factorRangeItem" sx={{ textAlign: 'center', width: '25%' }}>and</Typography>
                                <TextField
                                    className="factorRangeItem"
                                    label="Max"
                                    variant="standard"
                                    name="maxValue"
                                    onChange={(e) => rangeValueEntered(e, index)} // Pass index and value type
                                    type="number"
                                    value={rangeCondition.maxValue}
                                />
                            </Box>
                        )}

                        {rangeCondition.type === "Greater Than" && (
                            <Box className="factorRange">
                                <TextField
                                    className="factorRangeItem"
                                    label="Min"
                                    variant="standard"
                                    name="minValue"
                                    onChange={(e) => rangeValueEntered(e, index)} // Pass index and value type
                                    type="number"
                                    value={rangeCondition.minValue}
                                />
                            </Box>
                        )}

                        {rangeCondition.type === "Less Than" && (
                            <Box className="factorRange">
                                <TextField
                                    className="factorRangeItem"
                                    label="Max"
                                    variant="standard"
                                    name="maxValue"
                                    onChange={(e) => rangeValueEntered(e, index)} // Pass index and value type
                                    type="number"
                                    value={rangeCondition.maxValue}
                                />
                            </Box>
                        )}

                        {/* Delete Condition Button */}
                        <Button sx={{ mb: 1 }} variant="contained" color="error" onClick={() => removeCondition(index)}>
                            <Typography sx={{ fontWeight: '500', fontSize: 10 }}>Delete Condition</Typography>
                        </Button>
                    </Box>
                </div>
            ))}

            {/* Add Condition Button */}
            <Button sx={{ mb: 1 }} variant="contained" onClick={() => addCondition()}>
                <Typography sx={{ fontWeight: '500', fontSize: 10 }}>Add Condition</Typography>
            </Button>

            {/* Delete Trigger Button */}
            <Button sx={{ mb: 1 }} variant="contained" color="error" onClick={() => props.deleteSelf(props.id)}>
                <Typography sx={{ fontWeight: '500', fontSize: 10 }}>Delete Trigger</Typography>
            </Button>
        </Box>
    )
}

export default EnvironmentalFactorTrigger;