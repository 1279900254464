import cloneDeep from "lodash.clonedeep";
import { Account } from "./account.types";
import { Image } from "./util.types";

export enum QuestionType {
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    FREE_RESPONSE = 'FREE_RESPONSE',
    TASK = 'TASK'
}

export interface LessonPlan {
    id: number;
    name: string;
    owner: Account;
    questions: Question[];
}

export interface Question {
    id: number;
    title: string;
    prompt: string;
    type: QuestionType;
    choices: QuestionChoice[];
    screenshotRequired: boolean;
    number: number;
}

export interface QuestionChoice {
    id: number;
    choiceText: string;
    correctChoice: boolean;
}

export interface Response {
    id: number;
    account: Account;
    question: Question;
    response: string;
    choiceId: number;
    correct: boolean;
    image: Image;
}

export const DEFAULT_LESSON_PLAN = () => {
    return cloneDeep({
        id: null,
        name: "New Lesson Plan",
        owner: null,
        questions: []
    } as LessonPlan);
}

export const DEFAULT_QUESTION = () => {
    return cloneDeep({
        id: null,
        title: "New Question",
        prompt: "Default Prompt",
        type: QuestionType.MULTIPLE_CHOICE,
        number: null,
        screenshotRequired: false,
        choices: []
    } as Question);
}

export const DEFAULT_QUESTION_CHOICE = () => {
    return cloneDeep({
        id: null,
        choiceText: "Choice",
        correctChoice: false
    } as QuestionChoice);
}

export const DEFAULT_RESPONSE = () => {
    return cloneDeep({
        id: null,
        account: null,
        question: null,
        response: "",
        choiceId: -1,
        correct: false,
        image: null
    } as Response);
}
