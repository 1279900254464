import React, {useState, useEffect, useMemo, useRef} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Button,
    MenuItem,
    Menu,
    TextField, DialogContentText
} from "@mui/material";
import { Dropdown } from 'primereact/dropdown';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

interface InstructorAccessSharing {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    role: string;
    access: string; // 'owner', 'viewer', or 'editor'
}

interface Instructor {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    role: string;
}

interface LessonPlanAccessPopupProps {
    open: boolean;
    onClose: () => void;
    id: number;
    fetchAccessData: (id: number) => Promise<Map<string, InstructorAccessSharing[]>>;
    fetchAllInstructors: () => Promise<Instructor[]>;
    saveAccessData: (id: number, accessData: Map<string, InstructorAccessSharing[]>) => Promise<void>;
    handleClickVariant: (message: string, variant: string) => void;
}

const LessonPlanAccessPopup: React.FC<LessonPlanAccessPopupProps> = ({ open, onClose, id, fetchAccessData, fetchAllInstructors, saveAccessData, handleClickVariant }) => {
    const [localAccessData, setLocalAccessData] = useState<Map<string, InstructorAccessSharing[]>>(new Map());
    const [accountsArray, setAccountsArray] = useState<InstructorAccessSharing[]>([]);
    const [allInstructors, setAllInstructors] = useState<Instructor[]>([]);
    const [selectedInstructor, setSelectedInstructor] = useState<string | null>(null);

    // searching
    const [searchTerm, setSearchTerm] = useState('');
    const filteredInstructors = allInstructors.filter(item =>
        // allInstructors.findIndex(instructor => instructor.id === item.id) === -1 &&
        (item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.username.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // sorting columns
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'none' });
    const sortedAccounts = useMemo(() => {
        let sortableItems = [...accountsArray]; // Clone the array to avoid mutating the original data
        if (sortConfig.direction === 'none') {
            console.log("No sort:", sortConfig);
            return sortableItems;
        }
        if (sortConfig.key !== null) {
            console.log("key:", sortConfig.key);
            // if key is access
            if (sortConfig.key === 'access') {
                console.log("Sort by access:", sortConfig);
                // sort by owner, editor, viewer
                // when ascending, viewer is first, then editor, then owner
                // when descending, owner is first, then editor, then viewer
                sortableItems.sort((a, b) => {
                    const sortOrder = {
                        'owner': 0,
                        'editor': 1,
                        'viewer': 2
                    };

                    const aOrder = sortOrder[a[sortConfig.key]];
                    const bOrder = sortOrder[b[sortConfig.key]];

                    if (aOrder < bOrder) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    } else if (aOrder > bOrder) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    } else {
                        return 0;
                    }
                });
            } else {
                sortableItems.sort((a, b) => {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }
        }
        return sortableItems;
    }, [accountsArray, sortConfig]);
    const requestSort = (key: string) => {
        console.log("Request Sort:", sortConfig);
        let direction = 'none';
        if (sortConfig.key === key && sortConfig.direction === 'descending') {
            console.log("Ascending");
            direction = 'ascending';
        }
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            console.log("None");
            direction = 'none';
        }
        if (sortConfig.key === key && sortConfig.direction === 'none') {
            console.log("Descending");
            direction = 'descending';
        }
        if (sortConfig.key !== key) {
            console.log("Default descending");
            direction = 'descending';
        }
        console.log("Set Sort Config:", { key, direction });
        setSortConfig({ key, direction });
    };


    // switch between access viewer and editor
    const toggleRole = (accountId: string) => {
        if (highlightTimeoutRef.current) {
            clearTimeout(highlightTimeoutRef.current);
        }

        // Get the account
        const account = accountsArray.find(acc => acc.id === accountId);
        const newAccess = account.access === 'viewer' ? 'editors' : 'viewers';

        setAccountsArray(prevAccounts =>
            prevAccounts.map(account => {
                if (account.id === accountId && account.access !== 'owner') {
                    return {
                        ...account,
                        access: account.access === 'viewer' ? 'editor' : 'viewer',
                    };
                }
                return account;
            })
        );

        localAccessData.forEach((accounts, role) => {
            const index = accounts.findIndex(acc => acc.id === accountId);
            if (index > -1) {
                // Remove the account from the current role list
                accounts.splice(index, 1);
                // Add the account to the new role list
                const newRoleAccounts = localAccessData.get(newAccess) || [];
                newRoleAccounts.push(account);
                localAccessData.set(newAccess, newRoleAccounts);
            }
        });


        // Clear the highlight after some time
        highlightTimeoutRef.current = setTimeout(() => {
            setHighlightedRow(null); // Remove highlight
            highlightTimeoutRef.current = null; // Clear the stored timeout reference
        }, 1500); // after 1.5 seconds

        // Highlight the row
        setHighlightedRow(accountId);
    };


    // highlight the row that was just edited
    const [highlightedRow, setHighlightedRow] = useState(null);
    const highlightTimeoutRef = useRef(null);



    // attempt 2 - works
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (item: Instructor) => {
        console.log(item); // Or handle the selected item as needed
        // add the selected instructor to the list
        // unless they are already in the list
        if (accountsArray.find(account => account.id === item.id)) {
            console.log("Instructor already in list");
            setSearchTerm('');
            setAnchorEl(null);
            return;
        }
        setAccountsArray(prevAccounts => [...prevAccounts, {...item, access: 'viewer'}]);
        // add to local access data
        const newRoleAccounts = localAccessData.get('viewers') || [];
        // convert Instructor to InstructorAccessSharing
        const newInstructor: InstructorAccessSharing = {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            role: item.role,
            username: item.username,
            access: 'viewer'
        }
        newRoleAccounts.push(newInstructor);
        localAccessData.set('viewers', newRoleAccounts);
        setSearchTerm('');
        setAnchorEl(null);
    };


    const onClosePopup = () => {
        console.log("CLOSE", localAccessData);
        // Reset local access data
        setLocalAccessData(new Map());
        setAccountsArray([]);
        setAllInstructors([]);
        setSearchTerm('');
        setSortConfig({ key: null, direction: 'none' });
        // Call parent
        onClose();
    }



    useEffect(() => {
        if (open) {
            fetchAccessData(id).then(dataString => {
                console.log("Fetched access data:", dataString);
                // const parsedData = JSON.parse(dataString); // Convert string to object
                // Convert object to Map
                const accessMap = new Map<string, InstructorAccessSharing[]>(Object.entries(dataString));
                setLocalAccessData(accessMap);
                console.log("Local Access Data:", accessMap);

                // Add owner to accountsArray
                if (accessMap instanceof Map) {
                    if (accessMap.has('owner')) {
                        accessMap.get('owner').forEach(account => {
                            console.log("Owner:", account);
                            setAccountsArray(prevAccounts => [...prevAccounts, {...account, access: 'owner'}]);
                        });
                    }
                } else {
                    console.error('accessMap is not a Map:', accessMap);
                }

                // Add editor to accountsArray
                if (accessMap.has('editors')) {
                    accessMap.get('editors').forEach(account => {
                        console.log("Editor:", account);
                        setAccountsArray(prevAccounts => [...prevAccounts, {...account, access: 'editor'}]);
                    });
                }

                // Add viewer to accountsArray
                if (accessMap.has('viewers')) {
                    accessMap.get('viewers').forEach(account => {
                        console.log("Viewer:", account);
                        setAccountsArray(prevAccounts => [...prevAccounts, {...account, access: 'viewer'}]);
                    });
                }

                // Sort by last name
                accountsArray.sort((a, b) => a.lastName.localeCompare(b.lastName));

                console.log("Accounts Array:", accountsArray.toString());

                // Fetch all instructors
                fetchAllInstructors().then(instructors => {
                    setAllInstructors(instructors);
                    console.log("All Instructors:", allInstructors);
                }).catch(error => {
                    console.error("Failed to fetch all instructors", error);
                });

            }).catch(error => {
                console.error("Failed to fetch access data", error);
                // Handle error appropriately
            });
        } else {

        }
    }, [open, fetchAccessData, fetchAllInstructors, id]);



    const handleRoleChange = (accountId: string, newRole: string) => {
        // Iterate over each key (role) in the map
        localAccessData.forEach((accounts, role) => {
            const accountIndex = accounts.findIndex(account => account.id === accountId);
            if(accountIndex > -1) {
                // Remove the account from the current role list
                const [account] = accounts.splice(accountIndex, 1);
                // Update the account's role
                account.access = newRole;
                // Add the account to the new role list
                const newRoleAccounts = localAccessData.get(newRole) || [];
                newRoleAccounts.push(account);
                localAccessData.set(newRole, newRoleAccounts);
                // Trigger state update
                setLocalAccessData(new Map(localAccessData));
            }
        });
    };

    const handleRemoveAccess = (accountId: string) => {
        localAccessData.forEach((accounts, role) => {
            const index = accounts.findIndex(account => account.id === accountId);
            if (index > -1) {
                accounts.splice(index, 1);
                setLocalAccessData(new Map(localAccessData));
            }
        });

        // remove from accountsArray
        const index = accountsArray.findIndex(account => account.id === accountId);
        if (index > -1) {
            accountsArray.splice(index, 1);
            setAccountsArray([...accountsArray]);
        }
    };

    const handleUpdateAccess = () => {
        console.log("Updated Access:", localAccessData);

        saveAccessData(id, localAccessData).then(() => {
            console.log("Successfully saved access data");
            handleClickVariant("Successfully updated sharing access", 'success');
        }).catch(error => {
            console.error("Failed to save access data", error);
            handleClickVariant(error.response.data.message, 'error');
            // Handle error appropriately
        });

        onClosePopup(); // Close the dialog after updating
    };


    const handleAddInstructor = () => {
        if (!selectedInstructor) return;

        const instructorToAdd = allInstructors.find(instructor => instructor.id === selectedInstructor);
        if (!instructorToAdd) return;

        // Assuming we want to add them as viewers by default
        setAccountsArray(prevAccounts => [...prevAccounts, {...instructorToAdd, access: 'viewer'}]);
        setLocalAccessData(new Map(localAccessData));

        // Reset the selected instructor
        setSelectedInstructor(null);
    };

    return (
        <Dialog open={open} onClose={onClosePopup} maxWidth="md" fullWidth>
            <DialogTitle>Manage Lesson Plan Access</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Click on an instructor's access to switch between viewer and editor.<br/>
                    Click the trash can to remove access.<br/>
                    Add instructors to the lesson plan by clicking the "Add Instructor" button and selecting an
                    instructor from the list.
                </DialogContentText>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Button onClick={() => requestSort('lastName')}>Last Name</Button>
                                {sortConfig.key === 'lastName' && sortConfig.direction !== 'none' && (
                                    sortConfig.direction === 'ascending' ? <ArrowUpward/> : <ArrowDownward/>
                                )}
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => requestSort('firstName')}>First Name</Button>
                                {sortConfig.key === 'firstName' && sortConfig.direction !== 'none' && (
                                    sortConfig.direction === 'ascending' ? <ArrowUpward/> : <ArrowDownward/>
                                )}
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => requestSort('username')}>Username</Button>
                                {sortConfig.key === 'username' && sortConfig.direction !== 'none' && (
                                    sortConfig.direction === 'ascending' ? <ArrowUpward/> : <ArrowDownward/>
                                )}
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => requestSort('access')}>Access</Button>
                                {sortConfig.key === 'access' && sortConfig.direction !== 'none' && (
                                    sortConfig.direction === 'ascending' ? <ArrowUpward/> : <ArrowDownward/>
                                )}
                            </TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedAccounts.map((account) => (
                            <TableRow key={account.id}
                                      style={{
                                          backgroundColor: highlightedRow === account.id ? '#f0f0f0' : '', // Conditional background color
                                      }}
                            >
                                <TableCell>{account.lastName}</TableCell>
                                <TableCell>{account.firstName}</TableCell>
                                <TableCell>{account.username}</TableCell>
                                <TableCell onClick={() => account.access !== 'owner' && toggleRole(account.id)}
                                           style={{
                                               cursor: account.access !== 'owner' ? 'pointer' : 'not-allowed',
                                            }}>
                                    {account.access === 'owner' ? 'Owner' : (account.access.charAt(0).toUpperCase() + account.access.slice(1)) }
                                    {/*{account.access !== 'owner' && <EditIcon fontSize="small" />}*/}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => account.access !== 'owner' && handleRemoveAccess(account.id)}
                                        disabled={account.access === 'owner'} // Disables the button for 'owner'
                                        style={{
                                            opacity: account.access === 'owner' ? 0.5 : 1 // Optional: reduces opacity for 'owner'
                                        }}
                                    >
                                        <DeleteIcon
                                            style={{
                                                textDecoration: account.access === 'owner' ? 'line-through' : 'none'
                                            }}
                                        />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div>
                    <Button onClick={handleClick}>Add Instructor</Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem>
                            <TextField
                                autoFocus
                                placeholder="Search..."
                                variant="standard"
                                fullWidth
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </MenuItem>
                        {filteredInstructors.map((item) => (
                            <MenuItem key={item.id}
                                      onClick={() => handleClose(item)}
                                      disabled={!!accountsArray.find(instructor => instructor.username === item.username)}>
                                {item.lastName}, {item.firstName} ({item.username})
                            </MenuItem>
                        ))}
                    </Menu>

                </div>
                <Button onClick={handleUpdateAccess} color="primary" variant="contained" style={{marginTop: '20px'}}>Update
                    Access</Button>
            </DialogContent>
        </Dialog>
    );
};

export default LessonPlanAccessPopup;
