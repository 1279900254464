export function ColorScheme() {
    this.id = 0;
    this.name = "";
    this.chainColors = [];
    this.defaultColor = "#aaaaaa";
    this.defaultStyle = "Cartoons";

    this.getChainColor = function (index) {
        for (var i = 0; i < this.chainColors.length; i++) {
            var cc = this.chainColors[i];
            if (cc.chainIndex === index) {
                return cc.color;
            }
        }
        return this.defaultColor;
    };

    this.getChainStyle = function (index) {
        for (var i = 0; i < this.chainColors.length; i++) {
            var cc = this.chainColors[i];
            if (cc.chainIndex === index) {
                return cc.style;
            }
        }
        return this.defaultStyle;
    };
}
