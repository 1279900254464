import React, {ChangeEvent, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Button, FormControl,
    IconButton,
    MenuItem,
    Select,
    Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {FormControlLabel} from "@mui/material/";
import LessonPlanQuestionChoice from "./LessonPlanQuestionChoice";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import cloneDeep from "lodash.clonedeep";
import { DEFAULT_QUESTION_CHOICE, Question, QuestionChoice } from "../../utils/types/lessonPlan.types";

function LessonPlanQuestion(props: { question: Question, index: number,
    updateQuestionAndChoices,
    reorderQuestionAndChoices,
    deleteQuestionAndChoices }) {

    const questionUpdated = (e: ChangeEvent<HTMLInputElement>) => {
        let question = { ...props.question };
        question[e.target.name] = e.target.value;
        props.updateQuestionAndChoices(props.index, question);
    };


    const switchUpdated = (e: ChangeEvent<HTMLInputElement>) => {

        let question = cloneDeep(props.question);

        // WARNING: careful with the cloneDeep, it will not work if you have a function in the object.

        // and do not use ... when the object has embeded object inside--Yang

        // TODO: Allow the instructor to enable screenshots when the upload functionality is complete - 12-2-2022

        question[e.target.name] = !question[e.target.name];

        props.updateQuestionAndChoices(props.index, question);
    };

    const updateChoicesData = (choiceNumber: number, choice: QuestionChoice) => {
        let question = props.question;
        question.choices[choiceNumber] = choice;
        props.updateQuestionAndChoices(props.index, question);
    }

    const deleteQuestion = () => {props.deleteQuestionAndChoices(props.index);}

    const reorderDown = () => {props.reorderQuestionAndChoices(props.index, props.index - 1);}

    const reorderUp = () => {props.reorderQuestionAndChoices(props.index, props.index + 1);}

    const newChoice = () => {
        let question = props.question;
        question.choices.push(DEFAULT_QUESTION_CHOICE());
        props.updateQuestionAndChoices(props.index, question);
    }

    return (
        <Accordion>
            <AccordionSummary expandIcon={ <ExpandMoreIcon/> } aria-controls="panel1a-content" id="panel1a-header">
                <Typography sx={{maxHeight: 24, overflow: 'hidden'}}>{ "Question " + (props.index + 1) + ' - ' + props.question.title }</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                    <TextField required label="Title" name="title" variant="standard"
                               onChange={questionUpdated} value={props.question.title} />
                    <TextField required label="Prompt" name="prompt" variant="standard" multiline
                               maxRows={5} onChange={questionUpdated} value={props.question.prompt}/>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{mr: 1}}>Type: </Typography>
                            <Select required labelId="question-type-selector" size="small" label="Type" sx={{minWidth: '150px'}}
                                    name="type" variant="standard" onChange={questionUpdated} value={props.question.type}>
                                <MenuItem value="MULTIPLE_CHOICE">Multiple Choice</MenuItem>
                                <MenuItem value="TASK">Task</MenuItem>
                                <MenuItem value="FREE_RESPONSE">Free Response</MenuItem>
                            </Select>
                            { props.question.type === "MULTIPLE_CHOICE" && <IconButton aria-label="add" color="primary" onClick={newChoice}><AddIcon/></IconButton> }
                        </Box>


                        { props.question.type === "TASK" &&
                            <FormControlLabel control={
                                <Switch checked={props.question.screenshotRequired} name="screenshotRequired" onChange={switchUpdated}/>}
                                              label="Screenshot Required?" labelPlacement="top"/>
                        }

                    </Box>
                    { props.question.type === "MULTIPLE_CHOICE" &&
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Choice</TableCell>
                                        <TableCell align="center">Prompt</TableCell>
                                        <TableCell align="center">Correct?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.question.choices.map((choice, index) => (
                                        <LessonPlanQuestionChoice key={index} index={index} questionChoice={choice} updateChoice={updateChoicesData} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button variant="contained" color="error" onClick={deleteQuestion}>Delete Question</Button>
                        <Box>
                            <Button onClick={reorderDown}><ArrowUpwardIcon/></Button>
                            <Button onClick={reorderUp}><ArrowDownwardIcon/></Button>
                        </Box>
                    </Box>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
}

export default LessonPlanQuestion;