/**
 * This is the docker development environment file. This file is used throughout the 
 * frontend to determine which value to use for different scenarios.
 * 
 * The docker environment file is used when the frontend is running in a docker container.
 * This is done by a command in the Dockerfile that copies the docker environment file to
 * the 'src/environment.js' location.
 */
export const environment = {
    production: false,
    api: "/api",
}