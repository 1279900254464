import { Box } from "@mui/material";
import { ConfirmProvider } from 'material-ui-confirm';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import {Route, Routes, useParams} from 'react-router-dom';
import { AuthProvider } from './components/auth/AuthProvider';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import Menu from "./components/menu/Menu";
import './styles/App.css';
import ViewEnvironmentalFactors from './views/ViewEnvironmentalFactors';
import ViewEnvironmentalFactorTags from './views/ViewEnvironmentalFactorTags';
import HomePage from './views/Home';
import ViewLessonPlans from "./views/ViewLessonPlans";
import Login from './views/Login';
import ManageUsers from './views/ManageUsers';
import Register from './views/Register';
import StudentARView from './views/StudentARView';
import ViewMolecules from './views/ViewMolecules';
import StudentImage from './views/StudentImage';

function App() {
    return (
        <ConfirmProvider>
            <AuthProvider>
                <Routes>
                    <Route path='/login' element={
                        <Login />
                    }/>
                    <Route path='/register' element={
                        <Register />
                    }/>
                    <Route path='/' element={
                        <ProtectedRoute>
                            <Box>
                                <Menu showLinks={false}/>
                                <HomePage />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/manage_users' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <ManageUsers />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/view_molecule' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <ViewMolecules />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/environmental_factors' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <ViewEnvironmentalFactors />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/environmental_factor_tag' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <ViewEnvironmentalFactorTags />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/student_ar_view' element={
                        <ProtectedRoute>
                            <StudentARView />
                        </ProtectedRoute>
                    }/>
                    <Route path='/lesson_plans' element={
                        <ProtectedRoute>
                            <Box className="flexColumn" sx={{height: '100vh'}}>
                                <Menu showLinks={true}/>
                                <ViewLessonPlans />
                            </Box>
                        </ProtectedRoute>
                    }/>
                    <Route path='/images/:lessonPlanId/:questionId/:username' element={
                        <ProtectedRoute>
                            <StudentImageWithProps />
                        </ProtectedRoute>
                    }/>
                </Routes>
            </AuthProvider>
        </ConfirmProvider>
    );
}

const StudentImageWithProps = () => {
    const { lessonPlanId, questionId, username } = useParams();

    return <StudentImage lessonPlanId={lessonPlanId} questionId={questionId} username={username} />;
};

export default App;