import AddIcon from '@mui/icons-material/Add';
import { Box, Container, IconButton, List, ListItemButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import FactorDetails from '../components/environmental_factor/FactorDetails';
import '../styles/Molecule.css';
import { getEnvironmentalFactors } from "../utils/helpers/environmentalFactorsHelper";
import { DEFAULT_ENVIRONMENTAL_FACTOR, EnvironmentalFactor } from "../utils/types/environmentalFactor.types";

function ViewEnvironmentalFactors() {
    const [rows, setRows] = useState<EnvironmentalFactor[]>([]);

    // Edit Modal State
    const [factor, setFactor] = useState<EnvironmentalFactor>(DEFAULT_ENVIRONMENTAL_FACTOR());

    function refreshFactors(id: number) {
        getEnvironmentalFactors().then(function (environmentalFactors) {
            setRows(environmentalFactors);
            if (environmentalFactors.length > 0) {
                for (let i = 0; i < environmentalFactors.length; i++) {
                    if (environmentalFactors[i].id == id) {
                        setFactor(environmentalFactors[i]);
                        return;
                    }
                }
                setFactor(environmentalFactors[0]);
            } else {
                setFactor(DEFAULT_ENVIRONMENTAL_FACTOR());
            }
        })
    }

    useEffect(() => {refreshFactors(0);}, []);

    return (
        <Box className="flexColumn" sx={{flex: '1'}}>
            <Container id="moleculePanel" sx={{display: 'flex', backgroundColor: 'white', height: '100%'}}>
                <Box sx={{ boxShadow: '1px 0px rgb(0 0 0 / 10%)', flexBasis:'275px'}}>
                    <Box className="moleculesLabel" sx={{ display: 'flex', justifyContent: 'space-between', 
                        alignItems: 'center', p: 1, flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: 20 }}> Environmental Factors </Typography>
                        <IconButton aria-label='add' onClick={() => setFactor(DEFAULT_ENVIRONMENTAL_FACTOR())}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <List>
                        {rows.map((environmentalFactor, index) => (
                            <ListItemButton key={index} selected={factor !== null && factor.id === environmentalFactor.id}
                                            sx={{ px: 2, py: 1}} onClick={() => setFactor(environmentalFactor)}>
                                <Typography sx={{ textAlign: 'center' }}> {environmentalFactor.name} </Typography>
                            </ListItemButton>
                        ))}
                    </List> 
                </Box>
                {/* Right Column */}
                <FactorDetails factor={factor} updateFactors={refreshFactors}/>
            </Container>
        </Box>
    );
}

export default ViewEnvironmentalFactors;
