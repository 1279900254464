import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {EnvironmentalFactor} from "../types/environmentalFactor.types";

/**
 * READ ME.
 * 
 * Recently, we did a major overhaul of the backend and API.
 * However, we have not yet got around to front-end refactors.
 * Because of this, the methods here have been modified in such a way that new format responses from the API are converted to old formats.
 * Of course, this is a band-aid, and will need to be fixed.
 */

/**
 * Reads an environmental factor by id
 * @param factorId factor id
 * @returns factor data
 */
export async function getEnvironmentalFactor(id: number) {
    let factor: EnvironmentalFactor;
    await baseAxiosInstance.get( "/factors/" + id).then( async (response) => {
        factor = response.data;
    }).catch((error) => {throw error});
    return factor;
}

/**
 * Gets a list of environmental factors
 * @returns list of environmental factor data
 */
export async function getEnvironmentalFactors() {
    let factors: EnvironmentalFactor[];
    await baseAxiosInstance.get("/factors").then(function(response){
        factors = response.data;
    }).catch((error) => {throw(error)});
    return factors;
}

/**
 * Creates a new environmental factor
 * @param factorData factor data
 * @returns created factor id
 */
export async function postEnvironmentalFactor(factor: EnvironmentalFactor) {
    let newFactor: EnvironmentalFactor;
    await baseAxiosInstance.post("/factors", factor).then((response) => {
        newFactor = response.data;
    }).catch((error) => {throw error});
    return newFactor;
}

/**
 * Updates an environmental factor by id
 * @param factorId factor id
 * @param factorData factor data
 * @returns "OK." on update
 */
export async function putEnvironmentalFactor(id: number, factor: EnvironmentalFactor) {
    let newFactor: EnvironmentalFactor;
    await baseAxiosInstance.put("/factors/" + id, factor).then((response) => {
        newFactor = response.data;
    }).catch((error) => {throw error});
    return newFactor;
}

/**
 * Deletes an environmental factor by id
 * @param factorId factor id
 */
export async function deleteEnvironmentalFactor( factorId: number ) {
    await baseAxiosInstance.delete( "/factors/" + factorId).catch((error) => {throw error});
}