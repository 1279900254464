import cloneDeep from "lodash.clonedeep";
import { Image } from './util.types';

export interface EnvironmentalFactor {
    id: number;
    name: string;
    image: Image;
    unit: string;
    discrete: boolean;
    defaultValue: number;
    minValue: number;
    maxValue: number;
    description: string;
}

export interface ARFactorTrigger {
    id: number;
    factor: EnvironmentalFactor;
    value: number;
}

export interface RangeCondition {
    id: number;
    type: string;
    factor: EnvironmentalFactor;
    minValue: number;
    maxValue: number;
    description: string;
}

export const DEFAULT_ENVIRONMENTAL_FACTOR = () => {
    return cloneDeep({
        id: null,
        name: "New Environmental Factor",
        image: {
            id: null,
            image: ""
        },
        unit: "",
        discrete: false,
        defaultValue: 0,
        minValue: 0,
        maxValue: 0,
        description: ""
    } as EnvironmentalFactor);
}

export const DEFAULT_ENVIRONMENTAL_FACTOR_TAG = () => {
    return cloneDeep({
        id: null,
        factor: DEFAULT_ENVIRONMENTAL_FACTOR(),
        value: 0
    } as ARFactorTrigger);
}

let conditionCounter = 0;

export const DEFAULT_RANGE_CONDITION = () => {
    return cloneDeep({
        id: conditionCounter++,
        type: "",
        factor: null,
        minValue: 0,
        maxValue: 0,
        description: ""
    } as RangeCondition);
}
