export function FactorStateTrigger() {
    
    //Fields Set when JSON is parsed
    this.conditions = [];
    this.toState = null;
    
    this.isTrue = function(tags) {
		//for each condition 
		for (var i = 0; i < this.conditions.length; i++) {
			//	See if the condition evaluates to true
			if(!this.conditions[i].evaluate(tags)) {
				return false;
			}
		}
		return true;
    }
    
}