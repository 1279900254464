import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {CHOICES_URL, LESSON_PLANS_URL, QUESTIONS_URL} from "../constants/lessonPlanConstants";
import {APP_URL, SESSION_URL, SESSIONS_URL} from "../constants/sessionConstants";
import {getResponse} from "./responseHelper";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import { Question, QuestionChoice as LessonPlanQuestionChoice } from "../types/lessonPlan.types";

export async function joinSessionARView(sessionId: string) {
    console.log("Joining session " + sessionId);
    let lessonPlanID: number = -1;
    await baseAxiosInstance.get("/sessions/" + sessionId.toUpperCase()).then((response) => {
        lessonPlanID = response.data;
    }).catch((error) => {throw error});
    return lessonPlanID;
}

export async function getQuestionsWithResponses(sessionId: string) {
    console.log("Getting questions with responses for session " + sessionId);
    let questionsAndResponses: Question[] = [];
    await baseAxiosInstance.get(QUESTIONS_URL + APP_URL + SESSION_URL + "/" + sessionId).then(async (response) => {
        for (const questionId of response.data) {
            await getQuestionData( questionId, sessionId ).then(async (questionData: Question) => {
                console.log("Pushing question data", questionData);
                questionsAndResponses.push(questionData);
            }).catch((error) => {throw error});
        }
    }).catch((error) => {throw error});
    console.log("Returning questions and responses", questionsAndResponses);
    return questionsAndResponses;
}

export async function getQuestionData(questionId: number, sessionID: string) {
    console.log("Getting question data for question " + questionId + " and session " + sessionID);
    let questionData: Question = null;
    await baseAxiosInstance.get(QUESTIONS_URL + APP_URL + '/' + questionId + "/" + sessionID).then((response) => {
        questionData = response.data;
    }).catch((error) => {throw error});
    console.log("Returning question data", questionData);
    return questionData;
}

export async function getChoices(questionId: number, sessionID: string) {
    let choices: LessonPlanQuestionChoice[] = [];
    await baseAxiosInstance.get(QUESTIONS_URL + APP_URL + '/' + questionId + "/" + sessionID + CHOICES_URL).then((response) => {
        choices = response.data;
    }).catch((error) => {throw error});
    return choices;
}