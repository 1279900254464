import { Box, Typography } from "@mui/material";
import { VariantType, useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { addARTagForMolecule, getARTagForMolecule } from "../../utils/helpers/arTagHelper";
import { Molecule } from "../../utils/types/molecule.types";
import { ARMarker, Image } from "../../utils/types/util.types";

export function MoleculeTag( props: {molecule: Molecule; setMolecule }) {
    const [tagImage, setTagImage] = useState<Image>({id: null, image: ""});

    const { enqueueSnackbar } = useSnackbar();

    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    useEffect(() => {
        getARTagForMolecule(props.molecule.id).then((arTag: ARMarker) => {
            setTagImage(arTag.image);
        }).catch((error) => {
            if (error.toJSON().status === 404) {
                addARTagForMolecule(props.molecule.id).then((arTag: ARMarker) => {
                    setTagImage(arTag.image);
                }).catch((error) => {
                    throw(error);
                });
            } else {
                throw(error);
            }
        });
    }, [props.molecule.id]);

    return (
        <Box sx={{mb: 1}}>
            <Box>
                <Typography sx={{fontWeight: '500', fontSize: 20}}>Marker Image</Typography>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Box sx={{maxWidth: '200px', maxHeight: '200px'}} component='img' alt='AR Tag' src={`data:image/png;base64,${tagImage.image}`} />
            </Box>
        </Box>
    )
}
export default MoleculeTag;