import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useSnackbar, VariantType } from "notistack";
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../components/auth/AuthProvider';

function Login() {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const inputEntered = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const navigate: NavigateFunction = useNavigate();
    const location: Location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        auth.login(formData.username, formData.password).then(() => {
            navigate(from, { replace: true });
        }).catch((error: any) => {
            handleClickVariant(error, 'error');
        });
    };
    let auth = useAuth();

    // Type of state on the location object is literally unknown by the library.
    /* Maintains the original location that the user was redirected from, if they weren't
     redirected from somewhere we push them to the homepage.*/
    // @ts-ignore
    let from = location.state?.from?.pathname || '/';

    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    // one time, equivalent to componentdidmount
    useEffect(() => {document.title = 'AR Molecules';}, []);

    return (
            <Container component='main' maxWidth='xs'>
                <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
                    backgroundColor: 'white', borderRadius: '10px', padding: '2rem 1rem',
                    boxShadow: '0px 1px 3px black'}}>
                    <form onSubmit={handleSubmit}>
                        <Typography component='h3' variant='h3'>AR Molecules</Typography>
                        <Typography component='h1' variant='h1'>Sign in</Typography>
                        <TextField margin='normal' required fullWidth id='username' label='Username' name='username'
                                autoComplete='username' autoFocus onChange={inputEntered}/>
                        <TextField margin='normal' required fullWidth name='password' label='Password' type='password'
                                id='password' autoComplete='current-password' onChange={inputEntered}/>
                        <Button type='submit' fullWidth variant='contained' sx={{ my: 1 }}>Sign In</Button>
                    </form>
                    <Button onClick={() => {navigate('/register', { replace: true });}} fullWidth
                            variant='outlined' sx={{ mt: 1, mb: 1 }}>Register New User</Button>
                </Box>
            </Container>
    );
}

export default Login;
