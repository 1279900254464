export function RangeCondition(molecule) {
    
    //Fields Set when JSON is parsed
    this.factor = null;
	this.type = null;
    this.high = 0.0;
    this.low = 0.0;
    
    this.evaluate = function(tags) {
		//Get all of the molecule's factors 
		for (var i = 0; i < tags.length; i++) {  
			//See if it falls within range
			if (this.factor.id === tags[i][0].id){
				if(this.type === "Greater Than") { 
					if(tags[i][1] >= this.low){
						return true;
					}
				}
				else if(this.type === "Less Than") { 
					if(tags[i][1] <= this.high){
						return true;
					}
				}
				else if(this.type === "Between") { 
					if(tags[i][1] >= this.low && tags[i][1] <= this.high){
						return true;
					}
				}
			}
		}
		return false
    }
}