import { THREE } from '../../utils/constants/arViewConstants';
export function ARMarkerFactorTrigger(app) {
    // Reference to app
    this.app = app;

    // Factor id and value to set
    this.factorId = null;
    this.factorValue = null;

    // Name of AR marker to track with
    this.markerName = null;

    // Reference to ARMarker object
    this.arMarker = null;

    // encoded image data
    this.imageData = null;


    // Root and center THREE.js objects
    this.rootObject = new THREE.Object3D();
    this.centerObject = new THREE.Object3D();

    // Make center "hover" above root/ar marker
    this.centerObject.position.y = 1;
    this.rootObject.add(this.centerObject);

    // Image plain three.js object
    this.imagePlane = null;

    this.isVisible = function () {
        if (this.arMarker == null) return false;

        return this.arMarker.object.visible;
    };

    this.setVisible = function (visible) {
        if (this.imagePlane != null) this.imagePlane.visible = visible;
    };

    this.setImage = function (imageData) {
        this.imageData = imageData;

        // create textured image plane
        var texture, material;
        
        let image = new Image();
        image.src = this.imageData;

        texture = new THREE.Texture(image);
        image.onload = function () {
            texture.needsUpdate = true;
        };
        
        material = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true
        });

        // create plane mesh
        this.imagePlane = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(1, 1),
            material
        );
        this.imagePlane.material.side = THREE.DoubleSide;
    };

    this.setARMarker = function (markerId) {
        this.markerName = markerId;
        this.arMarker = this.app.getARMarkerById(markerId);
    };

    this.addSceneObjects = function () {
        if (this.imagePlane != null) this.app.scene.add(this.imagePlane);
    };

    this.removeSceneObjects = function () {
        if (this.imagePlane != null) this.app.scene.remove(this.imagePlane);
    };

    this.update = function (dt) {
        if (this.imagePlane != null && this.arMarker != null) {
            this.imagePlane.visible = this.arMarker.object.visible;
            if (this.imagePlane.visible) {
                // Manage root object position based on root arMarker
                this.rootObject.position.copy(this.arMarker.object.position);
                this.rootObject.rotation.copy(this.arMarker.object.rotation);
                this.rootObject.scale.copy(this.arMarker.object.scale);

                // Force update global position of center object
                this.rootObject.updateMatrixWorld(true);

                // Use global matrix of center object to position imagePlane
                var pos = new THREE.Vector3();
                var rot = new THREE.Quaternion();
                var sca = new THREE.Vector3();
                this.centerObject.getWorldPosition(pos);
                this.centerObject.getWorldQuaternion(rot);
                this.centerObject.getWorldScale(sca);

                // Update imagePlane position, rotation, and scale
                this.imagePlane.position.copy(pos);
                this.imagePlane.setRotationFromQuaternion(rot);
                this.imagePlane.scale.copy(sca);

                // Make image pane face camera (at origin)
                var origin = new THREE.Vector3(0, 0, 0);
                this.imagePlane.lookAt(origin);
            }
        }
    };
}
