import {addAuthHeader, baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {AxiosResponse} from "axios";
import {Role} from "../types/account.types";

// The API endpoint for the determining user roles
const API_ROLE = '/role';

/**
 * Format a received role from the backend into a human-readable string.
 * 
 * @param role The role enum to format
 * @returns Readable string representation, null if no match
 */
export function formatRole(role: string): string | null {
    switch (role) {
        case Role.ADMIN:
            return 'Administrator';
        case Role.INSTRUCTOR:
            return 'Instructor';
        case Role.STUDENT:
            return 'Student';
        default:
            return null;
    }
}

/**
 * Get the user's role from their JWT token.
 * 
 * @param token The user's JWT token
 * @returns The user's formatted role
 */
export async function getRole(token: string) {
    // Add the token to the base axios instance
    addAuthHeader(token);

    try {
        // Get the response from the backend and return the formatted string role
        const response = await baseAxiosInstance.get(API_ROLE + "/" + token);
        return formatRole(response?.data);
    } catch (err) {
        // Catch and log any error
        console.error(`[ERR]: error with getRole endpoint (${err})`);
    }
}
