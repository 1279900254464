import {useEffect, useRef, useState} from 'react'
import {Button} from "@mui/material"
import {THREE} from "../../utils/constants/arViewConstants"
import { ColorScheme } from '../../lib/js/ColorScheme'
import { ColoredModel } from '../../utils/types/molecule.types'
import { createMoleculeModel } from '../../utils/helpers/moleculeHelper'

/**
 * Renders a molecule in a box
 * 
 * Expects props:
 * posX: positive X is right
 * posY: positive Y is up
 * posZ: positive Z is near
 * rotX, rotY, rotZ
 * scaleX, scaleY, scaleZ
 * 
 * file: cif file for molecule
 * filename: filename of cif file, for detecting a change in file
 * lastModified: last modified time of cif file, for detecting a change in file
 * 
 * setField: function to modify rotation values according to mouse inputs
 * 
 * id: state number, used for computing color scheme
 * 
 * @returns 
 */
export function MoleculeRenderer(props: { model: ColoredModel, setRotations, newParams, index:number }) {
    // SpaceFilling for ball and stick model, Ribbon for ribbon model of a molecule
    let visualControlMode = 'Ribbon';

    // THREE.js Rendering Variables
    const renderer = useRef(null);
    const scene = useRef(null);
    const camera = useRef(null);

    // Mouse info
    const mousedown = useRef(false);
    const prevMouseCoords = useRef([0,0]);
    // Ref for mouselisteners to access the model's rotation, since they do not receive props changes automatically
    const rot = useRef(new THREE.Euler(props.model.baseModel.rotX, props.model.baseModel.rotY, props.model.baseModel.rotZ,"XYZ"));

    // Strength of the rotation from horizontal mouse drag, vertical mouse drag, and scrolling
    const horizontalDragFactor = 5;
    const verticalDragFactor = 5;
    const scrollFactor = 0.01;
    
    // Molecule model THREE.js object
    const [activeModel,setActiveModel] = useState(null)

    // Set up threejs things for rendering, and set up mouselisteners for controlling
    useEffect(() => {
        // Setup canvas
        let canvas = document.getElementById("renderCanvas" + props.index) as HTMLCanvasElement
        // Setup renderer
        renderer.current = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            canvas: canvas,
            preserveDrawingBuffer: true
        })
        renderer.current.setClearColor(new THREE.Color('lightgrey'), 0)
        renderer.current.setPixelRatio(window.devicePixelRatio)
        renderer.current.setSize(window.innerWidth/4,window.innerWidth/4)
        // Setup scene
        scene.current = new THREE.Scene()
        // Setup camera
        camera.current = new THREE.PerspectiveCamera(60,1,0.001,1000)
        camera.current.position.set(0,0,100)
        camera.current.aspect = 1
        scene.current.add(camera.current)
        // Setup lighting
        let directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
        directionalLight.position.z = 1
        scene.current.add(directionalLight)
        let ambientLight = new THREE.AmbientLight(0x808080) // soft white light
        scene.current.add(ambientLight)

        // Render loop
        requestAnimationFrame(function animate() {
            requestAnimationFrame(animate)
            renderer.current.render(scene.current, camera.current)
            camera.current.updateProjectionMatrix()
        })
    },[])

    // Replace molecule on file change
    useEffect(()=>{
        if(scene.current) {
            // Replace old model
            if(activeModel)
                scene.current.remove(activeModel)
            if(props?.model?.baseModel?.cifFile) {
                let centeredModel = {...props.model};
                centeredModel.baseModel = {...centeredModel.baseModel};
                // Remove properties from the basemodel, will put it back on the Object3D. It won't look right after saving and refreshing otherwise.
                centeredModel.baseModel.transX = 0;
                centeredModel.baseModel.transY = 0;
                centeredModel.baseModel.transZ = 0;
                centeredModel.baseModel.rotX = 0;
                centeredModel.baseModel.rotY = 0;
                centeredModel.baseModel.rotZ = 0;
                centeredModel.baseModel.scaleX = 1;
                centeredModel.baseModel.scaleY = 1;
                centeredModel.baseModel.scaleZ = 1;
                // Add model.
                let newActiveModel = createMoleculeModel(centeredModel,visualControlMode);
                scene.current.add(newActiveModel)
                // Add the properties back onto the Object3D.
                newActiveModel.position.set(props.model.baseModel.transX,props.model.baseModel.transY,props.model.baseModel.transZ)
                newActiveModel.rotation.set(props.model.baseModel.rotX * Math.PI/180,props.model.baseModel.rotY * Math.PI/180,props.model.baseModel.rotZ * Math.PI/180)
                newActiveModel.scale.set(props.model.baseModel.scaleX,props.model.baseModel.scaleY,props.model.baseModel.scaleZ)
                setActiveModel(newActiveModel)
            }
        }
    },[props?.model?.baseModel?.cifFile, scene.current])

    // Obtain the change in mouse position from this MouseEvent and apply rotation accordingly
    function applyMouseDrag(e: MouseEvent) {
        // Obtain mouse drag components from the MouseEvent
        let currentMouseCoords = getMouseCoords(e)
        let horizontalDrag = currentMouseCoords[0] - prevMouseCoords.current[0]
        let verticalDrag = currentMouseCoords[1] - prevMouseCoords.current[1]
        prevMouseCoords.current = currentMouseCoords

        // Apply rotation according to drag
        applyRotation(true,horizontalDrag,verticalDrag)
    }

    // Apply quaternion rotation for dragging or scrolling. 
    // Dragging uses first angle for horizontal drag magnitude, second angle for vertical drag magnitude.
    // Scrolling uses first angle for scroll magnitude
    function applyRotation(isDrag:boolean,angle1:number,angle2:number){
        // Convert current rotation from euler to quaternion
        let currentRot = new THREE.Quaternion().setFromEuler(rot.current)

        // Obtain axis and angle
        let axis:LiteMolTHREE.Vector3
        let angle:number
        // Drag
        if(isDrag) {
            if(angle1 == 0 && angle2 == 0)
                return
            axis = new THREE.Vector3(angle2*verticalDragFactor,angle1*horizontalDragFactor,0).normalize()
            angle = Math.sqrt((angle1*horizontalDragFactor)**2+(angle2*verticalDragFactor)**2)
        } else {
            // Scroll
            if(angle1 == 0)
                return
            axis = new THREE.Vector3(0,0,1)
            angle = angle1 * scrollFactor
        }

        // Apply rotation
        let appliedRot = new THREE.Quaternion().setFromAxisAngle(axis,angle)
        currentRot.multiplyQuaternions(appliedRot,currentRot)

        // Convert back to euler
        rot.current.setFromQuaternion(currentRot)

        // Update state
        props.setRotations([rot.current.x*180/Math.PI, rot.current.y*180/Math.PI, rot.current.z*180/Math.PI]);
    }

    // Obtain the coordinates of a MouseEvent relative to the canvas it occurs on
    // Coordinates (X,Y) will be in the interval [0,1]
    function getMouseCoords(e: MouseEvent) {
        let canvas = e.target as HTMLCanvasElement

        // Get X position relative to canvas X dimension. 0 is left edge, 1 is right edge.
        let currentX = canvas.offsetWidth == 0 ? 0 : e.offsetX/canvas.offsetWidth
        currentX = Math.max(0,Math.min(1,currentX))

        // Get Y position relative to canvas Y dimension. 0 is top edge, 1 is bottom edge.
        let currentY = canvas.offsetHeight == 0 ? 0 : e.offsetY / canvas.offsetHeight
        currentY = Math.max(0,Math.min(1,currentY))

        return [currentX,currentY]
    }

    // Set model position whenever a table value changes or the molecule model gets replaced
    useEffect(()=>{
        if(activeModel) {
            activeModel.position.set(props.model.baseModel.transX, props.model.baseModel.transY, props.model.baseModel.transZ);
            activeModel.rotation.set(props.model.baseModel.rotX/180*Math.PI, props.model.baseModel.rotY/180*Math.PI, props.model.baseModel.rotZ/180*Math.PI);
            activeModel.scale.set(props.model.baseModel.scaleX, props.model.baseModel.scaleY, props.model.baseModel.scaleZ);
        }
        // Update the rot ref
        rot.current = new THREE.Euler(props.model.baseModel.rotX/180*Math.PI, props.model.baseModel.rotY/180*Math.PI, props.model.baseModel.rotZ/180*Math.PI,"XYZ")
    }, [props.model, props.newParams])


    // State for detecting resizes
    const [dims, setDims] = useState([window.innerWidth,window.innerHeight])
    function onResize() {
        renderer.current.setPixelRatio(window.devicePixelRatio)
        renderer.current.setSize(window.innerWidth/4,window.innerWidth/4)
        setDims([window.innerWidth,window.innerHeight])
    }
    useEffect(() => {
        window.addEventListener("resize", () => onResize())
        return ()=>window.removeEventListener("resize", () => onResize())
    },[])
    
    // Compute color scheme based on state id, but this isn't ready yet because color scheme is in progress
    function makeColorScheme( ) {
        let color0;
        let color1;
        let color2;
        let color3;
    
        if (props.model.id % 2) {
            color0 = "#ff6666";
            color1 = "#66ff66";
            color2 = "#6666ff";
            color3 = "#ff66ff";
        } else {
            color0 = "#ff3333";
            color1 = "#33ff33";
            color2 = "#3333ff";
            color3 = "#ff33ff";
        }
        
        let newChain0 = {
            chain_index:0,
            color: color0,
            style: "BallsAndSticks",
            description: null,
        }
        let newChain1 = {
            chain_index:1,
            color: color1,
            style: "BallsAndSticks",
            description: null,
        }
        let newChain2 = {
            chain_index:2,
            color: color2,
            style: "BallsAndSticks",
            description: null,
        }
        let newChain3 = {
            chain_index:3,
            color: color3,
            style: "BallsAndSticks",
            description: null,
        }
        
        let scheme = new ColorScheme();
        scheme.id = props.model.id;
        scheme.name = "State" + props.model.id;
        scheme.chainColors = [newChain0,newChain1,newChain2,newChain3];
        scheme.defaultColor = "#99ff99";
        scheme.defaultStyle = "BallsAndSticks";
    
        return scheme
    }

    // Listeners for the canvas
    function handleMouseDown(e){
        e.nativeEvent.preventDefault() // Prevent highlighting text while rotating
        prevMouseCoords.current = getMouseCoords(e.nativeEvent)
        mousedown.current = true
    }
    function handleMouseUp(e){
        if(mousedown.current)
            applyMouseDrag(e.nativeEvent)
        mousedown.current = false
    }
    function handleMouseLeave(e){
        if(mousedown.current)
            applyMouseDrag(e.nativeEvent)
        mousedown.current = false
    }
    function handleMouseMove(e) {
        if(mousedown.current)
            applyMouseDrag(e.nativeEvent)
    }
    function handleWheel(e) {
        // e.nativeEvent.preventDefault() // Prevent scrolling away
        // applyRotation(false,e.nativeEvent.deltaY,0)
    }

    return( 
        // Whatever contains the canvas needs to have position: "relative" or else the canvas will extend out
        <div style={{position: "relative", height: window.innerWidth/4, width: window.innerWidth/4}}>
            <canvas 
                id={"renderCanvas" + props.index} style={{outline:"1px black solid"}}
                onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove} onWheel={handleWheel}
            />
        </div>
    )
}