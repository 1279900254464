import cloneDeep from "lodash.clonedeep";
import { RangeCondition } from "./environmentalFactor.types";

export interface Molecule {
    id: number;
    name: string;
    active: boolean;
    verticalOffset: number;
    defaultState: State;
    states: State[];
    description: string;
}

export interface State {
    id: number;
    name: string;
    coloredModel: ColoredModel;
    triggers: FactorStateTrigger[];
}

export interface ColoredModel {
    id: number;
    name: string;
    baseModel: BaseModel;
    colorScheme: ColorScheme;
    description: string;
}

export interface BaseModel {
    id: number;
    name: string;
    cifFile: string;
    transX: number;
    transY: number;
    transZ: number;
    rotX: number;
    rotY: number;
    rotZ: number;
    scaleX: number;
    scaleY: number;
    scaleZ: number;
    description: string;
}

export interface ColorScheme {
    id: number;
    name: string;
    chainColors: ChainColor[];
    defaultColor: string;
    defaultStyle: string;
    description: string;
}

export interface ChainColor {
    id: number;
    color: string;
    style: string;
    description: string;
}

export interface FactorStateTrigger {
    id: number;
    name: string;
    rangeConditions: RangeCondition[];
}

export const DEFAULT_MOLECULE = () => {
    return cloneDeep({
        id: null,
        name: "New Molecule",
        active: true,
        verticalOffset: 1.0,
        defaultState: DEFAULT_STATE(),
        states: [],
        description: ""
    } as Molecule);
}

export const DEFAULT_STATE = () => {
    return cloneDeep({
        id: null,
        name: "New State",
        coloredModel: {
            id: null,
            name: "",
            baseModel: {
                id: null,
                name: "",
                cifFile: "",
                transX: 0.0,
                transY: 0.0,
                transZ: 0.0,
                rotX: 0.0,
                rotY: 0.0,
                rotZ: 0.0,
                scaleX: 1.0,
                scaleY: 1.0,
                scaleZ: 1.0,
                description: "default"
            },
            colorScheme: {
                id: null,
                name: "",
                chainColors: [],
                defaultColor: "#55AAFF",
                defaultStyle: "Cartoons",
                description: "default"
            },
            description: "default"
        },
        triggers: []
    } as State);
}

let triggerCounter = 0;

export const DEFAULT_FACTOR_TRIGGER = (rangeConditions) => {
    return cloneDeep({
        id: triggerCounter++,
        name: "",
        rangeConditions: rangeConditions,
        description: "default"
    } as FactorStateTrigger);
}