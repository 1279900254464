import { useSnackbar, VariantType } from "notistack";
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import APIService from '../../utils/services/API.service';
import { addAuthHeader, removeAuthHeader } from "../../utils/services/BaseAxiosInstance.service";
import { AuthContextType, UserData } from '../../utils/types/auth.types';

/*
 NOTE: the user's context and useAuth method should probably live in a user service file. For the
 sake of time and understanding the mess Spring 2024 left us, Fall 2024 team did not refactor this.

 RANT SIDENOTE: this is why I prefer Angular (not AngularJS)
 */

// Context representing the user's current information (stored in the AuthContextType object)
const UserContext = createContext<AuthContextType>(null!);

// useAuth method allows 
export function useAuth() {return useContext(UserContext);}

/**
 * The AuthProvider component is a wrapper method that provides the user's authentication
 * details to the rest of the application.
 * 
 * @param children An object containing react nodes that are wrapped by this method
 */
export function AuthProvider({ children }: { children: ReactNode }) {
    // Snackbar is used to display pop-up messages to the user, such as error/success messages
    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    // Use cookies to store the user's JWT token. We get the user's current cookies and methods to set/remove them
    const [cookies, setCookie, removeCookie] = useCookies(["jwt"]);

    // Attempt to get the user's data if they are logged in
    const getUserDataIfLoggedIn = () => {
        // If there is not a jwt cookie, return null
        if ( !cookies.jwt ) return null;

        console.log("[INFO]: User is logged in from previous session, setting auth header");

        // There is a jwt cookie, so add it to the axios instance and return the jwt
        addAuthHeader( cookies.jwt );
        return {jwt: cookies.jwt}
    }

    // Create a state variable to store the user's data. We check if the user is logged in from a previous session
    let [userData, setUserData] = useState<UserData>( getUserDataIfLoggedIn );

    // Log in the user with a provided username and password
    const login = async (username: string, password: string) => {
        console.log(`[INFO]: attempting to log '${username}' in`);

        // Make the API call to the login endpoint (using the APIService because it is not authenticated)
        let token: string | null = null;
        try {
            token = await APIService.login(username, password);
        } catch (error) {
            console.error(`[ERR]: error logging user '${username}' in (${error})`);
            return;
        }

        console.log(`[INFO]: '${username}' successfully logged in! Setting auth headers, cookies, and user data`);

        // We can construct the user's data using the token
        const authenticatedUser: UserData = {
            jwt: token,
        };

        // Update the token and add it to the axios instance
        setCookie("jwt", token, {path: "/", maxAge: 604800});
        addAuthHeader( token );

        // Update the user's data
        setUserData(authenticatedUser);
        window.location.href = "/";
        return authenticatedUser;
    };

    // Logout by removing the jwt cookie and reloading the page
    const logout = () => {
        console.log(`[INFO]: logging user out`);

        removeCookie( "jwt", {path: "/"} );
        removeAuthHeader();
        window.location.href = "/login";
    };

    // Create a hook to validate the user's jwt token
    useEffect( () => {
        if (cookies.jwt == undefined) return;

        console.log(`[INFO]: auth provider react hook called, validating user's token`);

        // Logout if the token is no longer valid or the endpoint returns error
        APIService.validate(cookies.jwt).then((valid: boolean) => {
            if (!valid) {
                console.log(`[INFO]: token is no longer valid, logging out`);
                logout();
            } else {
                console.log(`[INFO]: token is still valid`);
            }
        }).catch((err) => {
            // Log and show snackbar error
            console.error(`[ERR]: error validating user's token, logging user out (${err})`);
            handleClickVariant(err.response.data.message, 'error');

            logout();
        });
    });

    // Return an HTML template that provides the user data, login, and logout methods to the rest of the application
    return (<UserContext.Provider value={ { userData, login, logout } }>{children}</UserContext.Provider>);
}