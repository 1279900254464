import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { MoleculeTypes } from '../../utils/types';
import { State, FactorStateTrigger, DEFAULT_FACTOR_TRIGGER } from '../../utils/types/molecule.types';
import EnvironmentalFactorTrigger from './EnvironmentalFactorTrigger';
import {getEnvironmentalFactors} from "../../utils/helpers/environmentalFactorsHelper";
import { MoleculeRenderer } from './MoleculeRenderer';
import { EnvironmentalFactor, DEFAULT_RANGE_CONDITION } from '../../utils/types/environmentalFactor.types';

/**
 * Expects props: 
 * stateParameters: an object containing 
 *   cifFile
 * id:
 * updateState
 * deleteState
 * addEnvironmentalFactorTrigger
 * deleteEnvironmentalFactorTrigger
 * environmentalFactorList
*/
function MoleculeState(props: { index: number; state: State; setState; deleteState }) {
    const newParams = useRef(0);
    const [triggers, setTriggers] = useState(props.state.triggers);
    useEffect(() => {
        setTriggers(props.state.triggers);
      }, [props.state.triggers]);
      
    const [factorOptions, setFactorOptions] = useState<EnvironmentalFactor[]>([]);
    if (props.index != -1) {
    }

    // Function for modifying textboxes in table, or rotation controls
    const textFieldEntered = (e: {target:{value:string,name:string}}) => {
        // Extract variables
        let value:number|string = e.target.value;
        let name = e.target.name;

        // If it's a valid number
        let valid = (Number(value) == parseFloat(value));
        if (valid) value = Number(value);

        let data = {...props.state};

        // Add lockProportions updates to other textfields
        if (lockProportions && valid) {
            let proportionValue = proportions.current[name];
            if(proportionValue && proportionValue != 0) {
                let factor = Number(value) / proportionValue;
                ["scaleX","scaleY","scaleZ"].forEach(e => {
                    if(proportions.current[e] != 0)
                        data.coloredModel.baseModel[e] = proportions.current[e] * factor;
                })
            }
        }

        // Update textbox
        switch(name) {
            case "transX":
            case "transY":
            case "transZ":
            case "rotX":
            case "rotY":
            case "rotZ":
            case "scaleX":
            case "scaleY":
            case "scaleZ":
                if (valid) {
                    data.coloredModel.baseModel[name] = Number(value);
                } else if(value === "") {
                    // @ts-ignore
                    data.coloredModel.baseModel[name] = "";
                }
                break;
        }
        
        props.setState(props.index, data);
        newParams.current++;
    }

    function setRotations(rotations) {
        let data = { ...props.state };

        data.coloredModel.baseModel.rotX = rotations[0];
        data.coloredModel.baseModel.rotY = rotations[1];
        data.coloredModel.baseModel.rotZ = rotations[2];
        props.setState(props.index, data);
        newParams.current++;
    }

    // Lock proportions checkbox controls
    const [lockProportions,setLockProportions] = useState(false)
    const proportions = useRef({scaleX:1, scaleY:1, scaleZ:1})
    const toggleLockProportions = () => {
        proportions.current = {scaleX: props.state.coloredModel.baseModel.scaleX, 
                               scaleY: props.state.coloredModel.baseModel.scaleY, 
                               scaleZ: props.state.coloredModel.baseModel.scaleZ}
        setLockProportions(!lockProportions)
    }

    // Function for file upload
    function fileUpload(selectorFiles: FileList | null) {
        // Update file, if it exists
        if (selectorFiles && selectorFiles[0]) {
            let data = { ...props.state };
            const reader = new FileReader();
            reader.readAsDataURL(selectorFiles[0]);
            reader.onload = () => {
                data.coloredModel.baseModel.cifFile = reader.result.toString();
                props.setState(props.index, data);
                newParams.current++;
            }
        }
    }

    function deleteState() {props.deleteState(props.index);}

    function updateEnvironmentalFactorTriggerState(index: number, factorTrigger: FactorStateTrigger) {
        console.log("Updating trigger at index:", index);

        // Create a new array with the updated trigger
        const newTriggers = [...props.state.triggers];
        newTriggers[index] = factorTrigger;  // Update the specific trigger at the index

        // Create a new state object with updated triggers
        const updatedState = { ...props.state, triggers: newTriggers };

        // Call the parent function to update state
        props.setState(props.index, updatedState);
    }

    function addEnvironmentalFactorTrigger() {
        console.log("adding trigger");
        const newTriggers = [...triggers, DEFAULT_FACTOR_TRIGGER([DEFAULT_RANGE_CONDITION()])];

        // Update local state
        setTriggers(newTriggers);

        // Update parent state
        const updatedState = { ...props.state, triggers: newTriggers };
        props.setState(props.index, updatedState);
    }

    function deleteEnvironmentalFactorTrigger(index) {
        console.log(triggers);
        console.log("Deleting trigger at index:", index); // Log the index for debugging
    
        const newTriggers = triggers.filter((_, i) => i !== index);
    
        // Update local state
        setTriggers(newTriggers);
    
        // Update parent state
        const updatedState = { ...props.state, triggers: newTriggers };
        props.setState(props.index, updatedState);
    }

    useEffect(() => {
        getEnvironmentalFactors().then((v: EnvironmentalFactor[]) => {
            setFactorOptions(v)
        })
    }, []);

    return (
        <Accordion sx={ {width: '100%'} }>
            <AccordionSummary expandIcon={ <ExpandMoreIcon/> } aria-controls="panel1a-content" id="panel1a-header">
                {
                    props.index < 0 ? <Typography sx={{fontWeight: '500', fontSize: 16}}> { "Base State" } </Typography>
                        : <Typography sx={{fontWeight: '500', fontSize: 16}}> { "State " + (props.index+1) } </Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                {/* File Upload button */ }
                <Box className="stateButtons" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Button sx={{mr: 1}} variant="contained" component="label">
                        Upload .cif File
                        <input type="file" onClick={e=>{(e.target as HTMLInputElement).value = null}} 
                        onChange={ (e) => fileUpload(e.target.files) } accept=".cif" hidden/>
                    </Button>
                </Box>
                
                <MoleculeRenderer model={ props.state.coloredModel } setRotations = { setRotations } newParams = { newParams.current } 
                    index = { props.index } />

                <TableContainer sx={{mb: 1}}>
                    <Table sx={{maxWidth: '90vw', overflow: 'auto'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Parameter</TableCell>
                                <TableCell align="center">X</TableCell>
                                <TableCell align="center">Y</TableCell>
                                <TableCell align="center">Z</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Model Position</TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="transX"
                                               onChange={ textFieldEntered } value={ props.state.coloredModel.baseModel.transX }
                                               type="number"/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="transY"
                                               onChange={ textFieldEntered } value={ props.state.coloredModel.baseModel.transY }
                                               type="number"/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="transZ"
                                               onChange={ textFieldEntered } value={ props.state.coloredModel.baseModel.transZ }
                                               type="number"/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Model Rotation</TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="rotX"
                                               onChange={ textFieldEntered } value={ props.state.coloredModel.baseModel.rotX }
                                               type="number" sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="rotY"
                                               onChange={ textFieldEntered } value={ props.state.coloredModel.baseModel.rotY }
                                               type="number" sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="rotZ"
                                               onChange={ textFieldEntered } value={ props.state.coloredModel.baseModel.rotZ }
                                               type="number" sx={ {maxWidth: 100} }/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Model Scale</TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="scaleX" onChange={ textFieldEntered }
                                               value={ props.state.coloredModel.baseModel.scaleX } type="number" inputProps={{step:0.1}}
                                               sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="scaleY" onChange={ textFieldEntered }
                                               value={ props.state.coloredModel.baseModel.scaleY } type="number" inputProps={{step:0.1}}
                                               sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="scaleZ" onChange={ textFieldEntered }
                                               value={ props.state.coloredModel.baseModel.scaleZ } type="number" inputProps={{step:0.1}}
                                               sx={ {maxWidth: 100} }/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Lock Model Scale</TableCell>
                                <TableCell>
                                    <input type="checkbox" checked={lockProportions} onChange={toggleLockProportions}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Extra Info For States other than the base state */ }
                { props.index >= 0 &&
                    <Grid container direction = "column" justifyContent = "flex-start" alignItems = "stretch">
                        {/* Environmental Factor Triggers */ }
                        <Grid item>
                            <Typography sx={{ fontWeight: '500', fontSize: 16, mb: 1 }}>
                                Switch to { "State " + (props.index + 1) } when:
                            </Typography>

                            {triggers.map((trigger, index) => (
                                <div key={trigger.id}>
                                    {index > 0 && (
                                        <Typography sx={{ fontWeight: '500', fontSize: 16, mb: 1 }}>
                                            Or
                                        </Typography>
                                    )}
                        
                                    <Box 
                                        key={trigger.id || index} 
                                        sx={{ 
                                            border: '1px solid gray',  // Gray border
                                            borderRadius: 2,           // Optional rounded corners
                                            padding: 2,                // Padding inside the box
                                            marginBottom: 2,           // Spacing between boxes
                                            backgroundColor: '#f5f5f5' // Light gray background
                                        }}
                                    >
                                        {/* Render the EnvironmentalFactorTrigger component */}
                                        <EnvironmentalFactorTrigger 
                                            id={index} 
                                            factorTrigger={trigger} 
                                            environmentalFactorList={factorOptions} 
                                            updateState={updateEnvironmentalFactorTriggerState} 
                                            deleteSelf={deleteEnvironmentalFactorTrigger} 
                                        />
                                    </Box>
                                </div>                    
                            ))}
                        </Grid>
                        <Box className="stateButtons" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'}}>
                            {/* Add Trigger Button */ }
                            <Button variant="contained" sx={{mb: 1}} onClick={ () => (addEnvironmentalFactorTrigger()) }>
                                Add Trigger
                            </Button>
                            {/* Delete State Button */ }
                            <Button variant="contained" sx={{mb: 1}} color="error" onClick={ deleteState }>
                                Delete State
                            </Button>
                        </Box>
                    </Grid>
                }
            </AccordionDetails>
        </Accordion>
    );
}

export default MoleculeState;
