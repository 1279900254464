import axios, { AxiosInstance } from "axios";
import { environment } from "../../environment";

/**
 * This is the base axios instance that will be used for all API calls. It is
 * configured to use the base URL from the environment file and provide a JWT
 * authorization header. This should be used for all calls except those in the
 * APIService (which can be accessed anonymously).
 */
export const baseAxiosInstance: AxiosInstance = axios.create({
    baseURL: environment.api,
});

let authInterceptorIds: number[] = [];  // Declare an array to store the interceptor IDs

/**
 * Adds the bearer token to every outgoing request. This file only needs to run
 * once, since it defines an interceptor in the baseAxiosInstance that attaches
 * the provided JWT to every outgoing request.
 * 
 * @note Maybe this interceptor gets added multiple times?
 * 
 * @param jwt valid jwt token that'll be used for every outgoing request
 */
export function addAuthHeader(jwt: string) {
    const interceptorId = baseAxiosInstance.interceptors.request.use((config) => {
        if (config.headers) {
            config.headers["Authorization"] = "Bearer " + jwt;
        }

        return config;
    })

    // Add the newly created interceptor ID to the array
    authInterceptorIds.push(interceptorId);
}

/**
 * Removes the bearer token for every outgoing request once logged out.
 * 
 * 
 * @param jwt valid jwt token that'll be used for every outgoing request
 */
export function removeAuthHeader() {
    authInterceptorIds.forEach(id => {
        baseAxiosInstance.interceptors.request.eject(id);
    });

    // Clear the array after removing all interceptors
    authInterceptorIds = [];
}
