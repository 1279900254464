import { THREE } from "../../utils/constants/arViewConstants";
export function AREnvironmentalFactor(app) {
  // Reference to app
  this.app = app;

  // Factor id and value to set
  this.factorId = null;

  // Name of AR marker to track with
  this.name = null;

  // Reference to ARMarker object
  this.arMarker = null;

  // encoded image data
  this.imageData = null;

  // Root and center THREE.js objects
  this.rootObject = new THREE.Object3D();
  this.centerObject = new THREE.Object3D();

  // Make center "hover" above root/ar marker
  this.centerObject.position.y = 1;
  this.rootObject.add(this.centerObject);

  // Image plain three.js object
  this.imagePlane = null;

  this.setVisible = function (visible) {
    if (this.imagePlane !== null) {
      this.imagePlane.visible = visible;
    }
  };

  this.setImage = function (imageData) {
    this.imageData = imageData;

    // create textured image plane
    var texture, material;
    this.imageData = imageData;

    // create textured image plane
    var texture, material;
    
    let image = new Image();
    image.src = this.imageData;

    texture = new THREE.Texture(image);
    texture.minFilter = THREE.LinearFilter
    image.onload = function () {
        texture.needsUpdate = true;
    };

    material = new THREE.SpriteMaterial({
      map: texture,
      transparent: true,
    });

    // create plane sprite
    this.imagePlane = new THREE.Sprite(material);
    this.imagePlane.material.side = THREE.DoubleSide;

  };

  this.setARMarker = function (artkMarker) {
    this.arMarker = artkMarker;
  };

  this.addSceneObjects = function () {
    if (this.imagePlane !== null) this.app.scene.add(this.imagePlane);
  };

  this.removeSceneObjects = function () {
    if (this.imagePlane !== null) this.app.scene.remove(this.imagePlane);
  };

  this.update = function (dt) {
    if (this.imagePlane !== null && this.arMarker !== null) {
      if (this.imagePlane.visible) {
        // Manage root object position based on root arMarker
        this.rootObject.position.copy(this.arMarker.object.position);
        this.rootObject.rotation.copy(this.arMarker.object.rotation);
        this.rootObject.scale.copy(this.arMarker.object.scale);

        // Force update global position of center object
        this.rootObject.updateMatrixWorld(true);
        this.centerObject.updateMatrixWorld(true);

        // // Use global matrix of center object to position imagePlane
        var pos = new THREE.Vector3();
        var rot = new THREE.Quaternion();
        var sca = new THREE.Vector3();
        this.centerObject.getWorldPosition(pos);
        this.centerObject.getWorldQuaternion(rot);
        this.centerObject.getWorldScale(sca);

        // // Update imagePlane position, rotation, and scale
        this.imagePlane.position.copy(pos);
        this.imagePlane.setRotationFromQuaternion(rot);
        this.imagePlane.scale.copy(new THREE.Vector3(sca.x, sca.x, sca.x));
      }
    }
  };
}