import {Typography} from "@mui/material";
import React from "react";

function FormLabel(props: {id: number, message: string}) {
    if ( props.id < 0 ) {
        return <Typography sx={{fontWeight: '500', fontSize: 20, mb: 1}}>Add {props.message}</Typography>
    }
    return <Typography sx={{fontWeight: '500', fontSize: 20, mb: 1}}>Edit {props.message}</Typography>

}
export default FormLabel;