import React, {useEffect, useMemo, useState} from "react";
import {
    Box, Button, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography
} from "@mui/material";
import {Radio} from "@mui/material/";
import {wait} from "@testing-library/user-event/dist/utils";
import {blob} from "stream/consumers";
import { DEFAULT_RESPONSE, Question, Response } from "../../utils/types/lessonPlan.types";
import { getResponse } from "../../utils/helpers/responseHelper";
import { VariantType, useSnackbar } from "notistack";


function SessionQuestion(props: { question: Question, response: Response, sessionId: string, index: number, updateResponse }) {

    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    const responseUpdated = (event) => {
        console.log("Response updated. Event and event target (response): ", event, event.target.value);
        // Update the response in the parent component
        let responseData = props.response;
        responseData.response = event.target.value;
        responseData.question = props.question;
        props.updateResponse(props.index, responseData);
    }
    // useEffect(() => {
    //    fileName = props.questionAndResponse.response.image;
    // });
//dependency change next rendering

    function blobToBase64(blob, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            callback(reader.result);
        });
        reader.readAsDataURL(blob);
    }

    function uploadScreenshot(event) {
        // response.image = event.target.files[0].name;
        // console.log(props.questionAndResponse.response.image)
        // props.questionAndResponse.response.files = event.target.files;

        // props.questionAndResponse.response.file = event.target.files[0];


        blobToBase64(event.target.files[0], (base64) => {
            let responseData = {...props.response}
            responseData.image = { id: null, image: base64 };
            responseData.question = props.question;
            props.updateResponse(props.index, responseData);
        })

        // let formData = new FormData();
        // formData.append('file', event.target.files[0]);
        // props.questionAndResponse.response.formData = formData;

        console.log(38, props);
    }

    function MyComponent(props) {
        const [fileName, setFileName] = useState('');

        function uploadScreenshot(event) {
            // update the response object
            setFileName(event.target.files[0].name);
        }

        return (
            <div>
                <input type="file" onChange={uploadScreenshot}/>
                <p>Selected file: {fileName}</p>
            </div>
        );
    }

    const choiceUpdated = (event) => {
        console.log("Choice updated. Event and event target (choice id): ", event, event.target.value);
        let responseData = {...props.response};
        console.log("Response data: ", responseData);
        responseData.choiceId = parseInt(event.target.value);
        responseData.question = props.question;

        console.log("Updating response: ", props.index, responseData);

        props.updateResponse(props.index, responseData);
        //props.updateResponse(props.index, responseData)
    }
//better organization of code --yang

    useEffect(() => {
        console.log(21, props.question, props.response);
    }, [props.question, props.response]);

    const renderMain = useMemo(() => {
        let res;
        const {type, screenshotRequired} = props.question ?? {}; // undefined null
        console.log(65, props.question)
        switch (type) {
            case "MULTIPLE_CHOICE":
                res = (
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {props.question.choices.map((choiceData, index) => (
                                    <TableRow key={index}>

                                        <TableCell scope="row">
                                            <Box className={'flexRow'}>
                                                <Radio
                                                    checked={props.response.choiceId === choiceData.id}

                                                    onClick={choiceUpdated}
                                                    value={choiceData.id === null ? undefined : choiceData.id}/>
                                                <Typography>{String.fromCharCode(65 + index)}</Typography>
                                            </Box>

                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{width: '100%'}}>{choiceData.choiceText}</Typography>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
                break;
            case "TASK":
                if (screenshotRequired) {
                    res = <>
                        <Button variant="contained" component="label">
                            Upload
                            <input onChange={uploadScreenshot} hidden accept="image/*" multiple type="file"/>
                        </Button>

                        <p>Selected file:
                            {props.response.image ? <img style={{width: '150px'}} src={props.response.image.image}/>:"No file selected"}</p>
                    </>

                }
                break;
            case "FREE_RESPONSE":
                res = <TextField
                    required
                    label="Response"
                    name="response"
                    variant="standard"
                    onChange={responseUpdated}
                    value={props.response ? props.response.response : ""}
                />
                break;
            default:
                break;
        }

        return res;
    }, [props]);

    return (
        <Box sx={{mt: 2}}>
            <Paper elevation={3} sx={{p: 2}}>
                <Typography>{"Question " + props.question.number}</Typography>
                <FormControl sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                    <Typography>{props.question.title}</Typography>
                    <Typography>{props.question.prompt}</Typography>
                    {renderMain}
                    {/*{ props.questionAndResponse.question.type === "MULTIPLE_CHOICE" &&*/}
                    {/*}*/}
                    {/*{props.questionAndResponse.question.type === "FREE_RESPONSE" &&*/}
                    {/*    <TextField required label="Response" name="response" variant="standard"*/}
                    {/*               onChange={responseUpdated} value={props.questionAndResponse.response.response} />*/}
                    {/*}*/}
                </FormControl>
            </Paper>
        </Box>

    );
}

export default SessionQuestion;