import React, {ChangeEvent, createRef, useEffect, useRef, useState} from 'react';
import {Box, Button, createTheme, TextField, ThemeProvider} from '@mui/material';
import {Link} from 'react-router-dom';
import Home from '@mui/icons-material/Home';
import {ARApp} from '../lib/AR/ARApp';
import '../styles/StudentARView.css';
import {useSnackbar, VariantType} from "notistack";
import ARViewSession from '../components/ar_view/ARViewSession';
import {joinSessionARView} from '../utils/helpers/sessionArViewHelper';
import {useConfirm} from "material-ui-confirm";
import * as htmlToImage from "html-to-image";
import {useScreenshot, createFileName} from "use-react-screenshot";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";


function StudentARView() {

    const mountRef = useRef(null);

    const {enqueueSnackbar} = useSnackbar();

    const [sessionJoined, setSessionJoined] = useState<boolean>(false);

    const [sessionButtonClicked, setSessionButtonClicked] = useState(false);

    const [sessionId, setSessionId] = useState('');

    const inputEntered = (e: ChangeEvent<HTMLInputElement>) => {
        setSessionId(e.target.value);
    };
    const theme = createTheme({palette: {mode: 'dark', primary: {main: '#393E46DD'}}});
    let ref2 = null;

    const app = useRef(null);

    function handleClickVariant(message: String, variant: VariantType) {
        enqueueSnackbar(message, {variant});
    }

    const confirm = useConfirm();
    const handleClickLeaveSession = () => {
        confirm({
            description: "You will no longer be a part of this session.",
            confirmationText: 'Leave'
        })

            .then(() => {
                setSessionId('');
                setSessionButtonClicked(false);
                setSessionJoined(false);
            }).catch(() => {
        });
    };

    function handleSessionButtonClick() {
        setSessionButtonClicked(!sessionButtonClicked);
        setSessionJoined(false);
    }

    function joinSession() {
        if (sessionId === null || sessionId === '') {
            handleClickVariant("Empty Session ID", 'error');
            return;
        }
        joinSessionARView(sessionId.toUpperCase()).then(() => {
            setSessionJoined(true);
        }).catch((error) => {
            setSessionJoined(false);
            handleClickVariant(error.response.data, 'error');
        });
    }

    let running = false;

    // capture camera method still in progress --Yang
    // const capture = async () => {
    //     const canvas = document.createElement("canvas");
    //     const context = canvas.getContext("2d");
    //
    //     const video = document.createElement("video");
    //
    //     try {
    //         const captureStream = await navigator.mediaDevices.getDisplayMedia();
    //         video.srcObject = captureStream;
    //         context.drawImage(video, 0, 0, window.innerWidth, window.outerHeight);
    //
    //         captureStream.getTracks().forEach(track => track.stop());
    //
    //         const frame = canvas.toDataURL("image/png");
    //
    //         let link = document.createElement('a');
    //         link.download = 'screenshot.png';
    //         link.href = frame;
    //         link.click();
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }
    //capture completed successfully
    const exportPng = () => {
        const input = document.getElementsByTagName('video')[0];

        var canvas = document.createElement('canvas');

        canvas.width = input.offsetWidth;

        canvas.height = input.offsetHeight;

        canvas.getContext('2d').scale(-1, 1);

        canvas.getContext('2d').translate(-canvas.width, 0);



        canvas.getContext('2d').drawImage(input, 0, 0, input.offsetWidth, input.offsetHeight);

        const imgData = canvas.toDataURL('image/png');

        const el = document.createElement('a');//link
        //set href to the image data uft
        el.href = imgData;

        console.log(116, el.href);
        el.download = 'TaskResponse.png';
        // 创建一个点击事件并对 a 标签进行触发
        // translates to "Creates a click event and fires it on the a tag."
        const event = new MouseEvent('click');
        el.dispatchEvent(event);
        //mirror the video
    }

    // const ref = createRef();

    // const takeScreenShot = async (node) => {
    //     return await htmlToImage.toJpeg(node);
    // };

    // const download = (image, { name = "img", extension = "jpg" } = {}) => {
    //     const a = document.createElement("a");
    //     a.href = image;
    //     a.download = createFileName(extension, name);
    //     a.click();
    // };

    // const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

//     async function screenshotOfARView() {
//         const video = document.querySelector('video');
//         const canvas = document.querySelector('canvas');
//
// // Draw the current frame of the video onto the canvas
//         canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
//
// // Get the screenshot data as a data URL
//         const screenshotData = canvas.toDataURL('image/png');
//
// // Create a download link for the screenshot
//         const downloadLink = document.createElement('a');
//         downloadLink.download = 'screenshot.png';
//         downloadLink.href = screenshotData;
//
// // Trigger a click on the download link to start the download
//         document.body.appendChild(downloadLink);
//         downloadLink.click();
//         document.body.removeChild(downloadLink);
//     }

    function takeScreenshot() {
        const renderer = app.current.renderer;
        const scene = app.current.scene;
        const camera = app.current.camera;
        const videoElement = app.current.arToolkitSource.domElement;

        // Create a temporary canvas to draw the webcam feed and the molecule on top of it
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = window.innerWidth;
        tempCanvas.height = window.innerHeight;
        const ctx = tempCanvas.getContext('2d');

        // Draw the webcam feed first
        if (videoElement instanceof HTMLVideoElement) {
            ctx.drawImage(videoElement, 0, 0, tempCanvas.width, tempCanvas.height);
        }

        // Then, draw the Three.js scene on top of the webcam feed
        renderer.setRenderTarget(null); // Ensure we're rendering to the screen
        renderer.render(scene, camera);

        ctx.drawImage(renderer.domElement, 0, 0, tempCanvas.width, tempCanvas.height);

        // Convert the temporary canvas to a data URL for the screenshot
        const dataUrl = tempCanvas.toDataURL('image/png');

        // TODO: refactor to jsx
        // Create an overlay or modal to show the image with instructions for saving
        const overlay = document.createElement('div');
        overlay.setAttribute('style', `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 10000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `);
        overlay.innerHTML = `<div style="margin: 20px; text-align: center;">
        <p style="color: white; font-size: 20px; margin-bottom: 20px;">Tap and hold the image below to save it.</p>
        <button style="padding: 10px; font-size: 16px; background-color: #f00; color: white; border: none; border-radius: 5px;" onclick="this.parentNode.parentNode.remove()">Close</button>
    </div>`;
        const img = new Image();
        img.src = dataUrl;
        img.style.maxWidth = '90%';
        img.style.maxHeight = '80%';
        img.style.marginBottom = '20px';
        overlay.appendChild(img);

        document.body.appendChild(overlay);
    }




    function removeVid() {
        if (ref2 !== null) {
            ref2.removeChild(ref2.lastChild)
            let vidArr = document.getElementsByTagName('video');
            console.log(934, vidArr)//debug code
            for (let i = vidArr.length - 1; i >= 0; i--) {
                vidArr[i].remove();
            }
        }
    }

    useEffect(() => {
        app.current = new ARApp();
        // app.mirror = true;
        if (!running) {
            app.current.init(mountRef);
            app.current.run();
            running = true;
        }
        ref2 = mountRef.current;
        console.log(933, ref2, app)//debug code
        return () => {
            removeVid();
            app.current.stop();
        }
    }, []);

    function handleArrowButtonClicked() {
        if(app.current)
            app.current.toggleArrowOverlay()
    }


    return (
        <Box id='top-level-container' ref={mountRef}>
            <Box id='ar-header' className='ar-row'
                 sx={{display: 'inline', flexDirection: 'column', alignItems: 'start', gap: '10px'}}>
                <Box>
                    <ThemeProvider theme={theme}> <Button component={Link} to={'/'} variant='contained'> <Home/>
                    </Button> </ThemeProvider>
                </Box>
                <Box sx={{
                    display: 'inline-block',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '10px',
                    marginTop: '10px'
                }}>
                    <ThemeProvider theme={theme}>

                        {sessionJoined ?
                            (<Button onClick={handleClickLeaveSession} variant='contained'>Leave Session</Button>) :
                            (<Button onClick={handleSessionButtonClick} variant='contained'>Join Session</Button>)
                        }
                        {sessionButtonClicked === true && !sessionJoined && (
                            <Box className={'flexRow'} sx={{width: "60%", alignItems: 'center', mt: 1}}>

                                <TextField sx={{mr: 1}} size="small" label='Session ID' variant='filled'
                                           name='sessionID' onChange={inputEntered}/>
                                <Button onClick={joinSession} variant='contained'>Join</Button>

                            </Box>
                        )}
                    </ThemeProvider>
                </Box>

                <Box sx={{display: 'block', flexDirection: 'column', alignItems: 'center'}}>
                    <ThemeProvider theme={theme}>
                        <Button onClick={takeScreenshot} variant='contained'>ScreenShot</Button>
                    </ThemeProvider>
                </Box>

                <Box>
                    <ThemeProvider theme={theme}>
                        <Button onClick={handleArrowButtonClicked} sx={{mt:1, alignItems:'flex-end'}} variant='contained'>Toggle Arrow</Button>
                    </ThemeProvider>
                </Box>

            </Box>

            <Box>
                {sessionJoined && <ARViewSession sessionId={sessionId}/>}
            </Box>

        </Box>
    );
}

export default StudentARView;