import React from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface CSVDownloadProps {
  csvContent: string;
  fileName: string;
}

const LessonPlanCSVDownload: React.FC<CSVDownloadProps> = ({ csvContent, fileName }) => {
  const downloadCSV = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

    const dateTimeString = formattedDate + 'at' + currentDate.getHours() + "." + currentDate.getMinutes() + "." + currentDate.getSeconds();

    const modifiedFileName = `${fileName}_${dateTimeString}.csv`;

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', modifiedFileName);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <span 
      onClick={downloadCSV} 
      style={{ 
        cursor: 'pointer', 
        display: 'inline-flex', 
        alignItems: 'center',
        padding: '8px',
        borderRadius: '50%',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
        transition: 'box-shadow 0.3s ease'
      }}
      onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 8px 16px rgba(0, 0, 0, 0.3)'}
      onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)'}
    >
      <FileDownloadIcon/>
    </span>
  );
};

export default LessonPlanCSVDownload;
