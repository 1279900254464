import React, { ChangeEvent, useEffect, useState } from 'react';
import {Box, TextField, Typography, MenuItem, Select, FormControl} from '@mui/material';
import '../../styles/Views.css';
import {useSnackbar, VariantType} from "notistack";
import FormLabel from "../utils/FormLabel";
import Toolbar from "../utils/Toolbar";
import {deleteEnvironmentalFactorTag, getEnvironmentalFactorTagImage, postEnvironmentalFactorTag, putEnvironmentalFactorTag} from "../../utils/helpers/environmentalFactorsTagHelper";
import {getEnvironmentalFactors, postEnvironmentalFactor} from "../../utils/helpers/environmentalFactorsHelper";
import { ARFactorTrigger, EnvironmentalFactor } from '../../utils/types/environmentalFactor.types';
import { ARMarker, Image } from '../../utils/types/util.types';
import { addARTagForFactorTrigger, getARTagForFactorTrigger } from '../../utils/helpers/arTagHelper';

function EnvironmentalFactorTagDetails(props: {environmentalFactorTag: ARFactorTrigger, update}) {
    const [factorArr, setFactorArr] = useState<EnvironmentalFactor[]>([]);
    const [environmentalFactorTagData, setEnvironmentalFactorTagData] = useState<ARFactorTrigger>(props.environmentalFactorTag);
    const [tagImage, setTagImage] = useState<Image>(null);

    const updateEnvironmentalFactorTagData = (e: ChangeEvent<HTMLInputElement>) => {
        let data = { ...environmentalFactorTagData };
        if (e.target.name == 'factorId') {
            let fullFactor = null;
            factorArr.forEach((factor) => {
                if (factor.id == Number(e.target.value)) {
                    fullFactor = factor;
                }
            });
            if (fullFactor) {
                data.factor = fullFactor;
                setEnvironmentalFactorTagData(data);
            } else {
                handleClickVariant("Environmental factor not valid", 'error');
            }
        } else {
            data[e.target.name] = e.target.value;
            setEnvironmentalFactorTagData(data);
        }
    };

    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    function saveEnvironmentalFactorTag(): void {
        if (environmentalFactorTagData.id) {
            putEnvironmentalFactorTag(environmentalFactorTagData.id, environmentalFactorTagData).then(() => {
                handleClickVariant("Tag Successfully Saved", 'success');
                props.update(environmentalFactorTagData);
            }).catch((error) => {
                handleClickVariant(error.response.data.message, 'error');
            });
        } else {
            postEnvironmentalFactorTag(environmentalFactorTagData).then(() => {
                handleClickVariant("Tag Successfully Added", 'success');
                props.update(environmentalFactorTagData);
            }).catch((error) => {
                handleClickVariant(error.response.data.message, 'error');
            });
        }
    }

    function deleteAAA(): void {
        deleteEnvironmentalFactorTag(environmentalFactorTagData.id);
    }

    function getFactors() {
        getEnvironmentalFactors().then((factors) => {
            setFactorArr(factors);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function getImage() {
        getARTagForFactorTrigger(props.environmentalFactorTag.id).then((arTag: ARMarker) => {
            setTagImage(arTag.image);
        }).catch((error) => {
            if (error.toJSON().status === 404) {
                addARTagForFactorTrigger(props.environmentalFactorTag.id).then((arTag: ARMarker) => {
                    setTagImage(arTag.image);
                }).catch((error) => {
                    throw(error);
                });
            } else {
                throw(error);
            }
        });
    }

    useEffect(() => {
        getFactors();
        setEnvironmentalFactorTagData(props.environmentalFactorTag);
        if (props.environmentalFactorTag.id) {
            getImage();
        }
    }, [props.environmentalFactorTag]);

    return (
        <Box id={'rightPanel'} className={'flexColumn'}>
            <FormLabel id={environmentalFactorTagData.id} message={'Environmental Factor Tag'} />
            <FormControl className='detailsForm'>
                <Box className={'flexRow'}>
                    <Box id={'factorValue'} className={'flexColumn'}>
                        <Box id={'factor'} className={'flexRow'}>
                            <Typography>Assigned to Factor: </Typography>
                            <Select value={environmentalFactorTagData.factor.id === null ? '' : environmentalFactorTagData.factor.id}
                                    onChange={updateEnvironmentalFactorTagData} name='factorId' label='factors'>
                                {factorArr.map(factor => (
                                    <MenuItem key={factor.id} value={factor.id}>{factor.name}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box id={'value'} className={'flexRow'}>
                            <Typography>Value: </Typography>
                            <TextField type='number' name='value' onChange={updateEnvironmentalFactorTagData} value={environmentalFactorTagData.value} />
                        </Box>
                    </Box>
                    <Toolbar id={environmentalFactorTagData.id} message={"Deletion of this tag is permanent and cannot be undone"} edit={() => {saveEnvironmentalFactorTag()}} delete={() => {deleteAAA()}}/>
                </Box>

                <Box className={'imageContainer'}>
                    { props.environmentalFactorTag.id && tagImage && <Box className='image' component='img' alt='Image not Available' src={`data:image/png;base64,${tagImage.image}`} /> }
                </Box>
            </FormControl>
        </Box>
    );
}

export default EnvironmentalFactorTagDetails;
