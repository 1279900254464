import { baseAxiosInstance } from "../services/BaseAxiosInstance.service";
import { Category, CategoryAPI } from "../types/category.types";

export async function getCategories() {
	let categories: Category[];
	await baseAxiosInstance.get("/categories").then(function(response){
        categories = response.data;
    }).catch((error) => {throw(error)});
    return categories;
}

export async function postCategory(name: String) {
	let category: CategoryAPI;
    await baseAxiosInstance.post("/categories", name, {
        headers: { 'Content-Type': 'text/plain' }
    }).then((response) => {
        category = response.data;
    }).catch((error) => {throw error});
    return category;
}

export async function deleteCategory(id: number) {
	await baseAxiosInstance.delete( "/categories/" + id).catch((error) => {throw error});
}

export async function addToCategory(id: number, lessonPlanId: number) {
	let category: CategoryAPI;
	await baseAxiosInstance.post("/categories/" + id + "/" + lessonPlanId).then((response) => {
        category = response.data;
    }).catch((error) => {throw error});
    return category;
}

export async function removeFromCategory(id: number, lessonPlanId: number) {
	await baseAxiosInstance.delete( "/categories/" + id + "/" + lessonPlanId).catch((error) => {throw error});
}