import { useEffect, useState } from 'react';
import {Box, Container, List, ListItemButton, Typography, IconButton} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Views.css';
import EnvironmentalFactorTagDetails from '../components/environmental_factor_tag/EnvironmentalFactorTagDetails';
import {useSnackbar, VariantType} from "notistack";
import {getEnvironmentalFactorTags} from "../utils/helpers/environmentalFactorsTagHelper";
import { ARFactorTrigger, DEFAULT_ENVIRONMENTAL_FACTOR, DEFAULT_ENVIRONMENTAL_FACTOR_TAG } from '../utils/types/environmentalFactor.types';

function ViewEnvironmentalFactorTags() {
    const [rows, setRows] = useState<ARFactorTrigger[]>([]);

    // Edit Modal State
    const [factorTrigger, setFactorTrigger] = useState<ARFactorTrigger>(DEFAULT_ENVIRONMENTAL_FACTOR_TAG());

    const { enqueueSnackbar } = useSnackbar();

    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    function refreshTags(tag)  {
        console.log(tag);
        getEnvironmentalFactorTags().then((tags) => {
            setRows(tags);
            if (tag !== null) {
                setFactorTrigger(tag);
            } else if (tags.length > 0) {
                setFactorTrigger(tags[0]);
            } else {
                setFactorTrigger(null);
            }
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {refreshTags(null);}, []);

    return (
        <Box className="flexColumn" sx={{flex: '1'}}>
            <Container id='panel' className='viewPanel' sx={{display: 'flex'}}>
                
                {/* Left Column */}
                <Box className='leftPanel'>
                    <Box id={'leftPanelTitleContainer'} className={'flexRow'}>
                        <Typography id={'leftPanelTitle'}>Env. Factor Tags</Typography>
                        <IconButton aria-label="add" onClick={() => setFactorTrigger(null)}><AddIcon/></IconButton>
                    </Box>
                    <List>
                        {rows.map(tag => (
                            <ListItemButton key={tag.id} selected={factorTrigger !== null && factorTrigger.id === tag.id} className='leftPanelList'
                                            onClick={() => {setFactorTrigger(tag);}}>
                                <Typography className='leftPanelListItem'>
                                    {'Assigned to ' +  tag.factor.name + ': ' + tag.value}
                                </Typography>
                            </ListItemButton>
                        ))}
                    </List>
                </Box>

                {/* Right Column */}
                <EnvironmentalFactorTagDetails environmentalFactorTag={factorTrigger == null ? DEFAULT_ENVIRONMENTAL_FACTOR_TAG() : factorTrigger } update={refreshTags}/>
            </Container>
        </Box>
    );
}

export default ViewEnvironmentalFactorTags;
