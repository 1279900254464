import React, {useEffect, useState} from 'react';
import {Box, SwipeableDrawer, Typography, Button, FormControl, Container} from '@mui/material';
import {useSnackbar, VariantType} from "notistack";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {getQuestionsWithResponses} from "../../utils/helpers/sessionArViewHelper";
import SessionQuestion from "./SessionQuestion";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {getResponse, submitResponses} from "../../utils/helpers/responseHelper";
import { DEFAULT_RESPONSE, Question, Response } from '../../utils/types/lessonPlan.types';

function ARViewSession(props: {sessionId: string}) {
    const drawerBleeding = 30;
    const [questions, setQuestions] = useState<Question[]>([]);
    const [responses, setResponses] = useState<Response[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const toggleDrawer = (newOpen: boolean) => () => {setOpen(newOpen);};
    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    function getSessionData() {
        getQuestionsWithResponses( props.sessionId ).then((questionIdsAndResponses) => {
            console.log("Question IDs and responses returned from getQuestionsWithResponses", questionIdsAndResponses);
            setQuestions(questionIdsAndResponses);

            for (let i = 0; i < questionIdsAndResponses.length; i++) {
                console.log("Getting response for question", questionIdsAndResponses[i].id);
                getResponse(questionIdsAndResponses[i].id, props.sessionId).then((response) => {
                    console.log("Response for question", questionIdsAndResponses[i].id, response);
                    let tmpResponses = responses;
                    tmpResponses[i] = response;
                    // console.log(tmpResponses);
                    console.log("Setting responses", tmpResponses);
                    setResponses(tmpResponses);
                }).catch((error) => {
                    if (error.response.status == 404) {
                        console.log("No response found for question", questionIdsAndResponses[i].id);
                        let tmpResponses = responses;
                        tmpResponses[i] = DEFAULT_RESPONSE();
                        // console.log(tmpResponses);
                        console.log("Setting responses", tmpResponses);
                        setResponses(tmpResponses);
                    } else {
                        handleClickVariant(error.response.data.message, 'error');
                    }
                });
            }
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function updateResponse( index: number, response: Response ) {
        console.log("Updating response in ARViewSession idx and response", index, response);
        let responsesData =  [...responses];
        responsesData[index] = response;
        console.log("Responses data", responsesData);
        // this.forceUpdate();
        setResponses(responsesData);
    }


    function submitHandler() {
        submitResponses( questions, responses, props.sessionId ).then(() => {
            handleClickVariant("Responses Submitted", 'success');
        }).catch((error) => {
            console.log("Error submitting responses", error);
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {
        if ( props.sessionId !== null && props.sessionId !== '' ) {
            getSessionData();
        } else {
            setQuestions([]);
            setResponses([]);
        }
    }, [props.sessionId]);

    return (
        <Box>
            <Box sx={{ textAlign: 'center', backgroundColor: 'white', borderRadius: '100px 100px 0 0'}}>
                <Button onClick={toggleDrawer(true)}> <ExpandLessIcon/> </Button>
            </Box>

            <SwipeableDrawer anchor="bottom" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}
                             disableSwipeToOpen={false} ModalProps={{keepMounted: true}}
                             swipeAreaWidth={open ? drawerBleeding : 0}>

                <Box className={'flexRow'} sx={{justifyContent: 'center'}}>
                    <Button onClick={toggleDrawer(false)}><DragHandleIcon/></Button>
                </Box>

                <Box sx={{height: '100%', overflow: 'auto', p: 2}}>

                    <Typography
                        sx={{fontWeight: '500', fontSize: 20, mb: 1}}>Session: {props.sessionId}</Typography>
                    <FormControl variant={"outlined"}>

                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                            <Typography sx={{fontWeight: '500', fontSize: 20}}>Questions</Typography>
                        </Box>

                        <Container className="flexColumn" sx={{mb: 1}}>
                            {responses.map((response, index) => (
                                <SessionQuestion key={index} index={index} question={questions[index]} response={response} sessionId={props.sessionId} updateResponse={updateResponse}/>
                            ))}
                        </Container>

                        <Button variant="contained" color="success" onClick={submitHandler}>Submit Responses</Button>

                    </FormControl>

                </Box>
            </SwipeableDrawer>
        </Box>  
    );
}

export default ARViewSession;