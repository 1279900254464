import LiteMol from 'litemol/dist/js/LiteMol-plugin.min';

export function UniformColoring(colorString){

    this.colors = [];
    this.colors.push(LiteMol.Visualization.Color.fromHexString(colorString));

    this.getColorIndex = function(elementIndex){
        return 0;
    };

}
