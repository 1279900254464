import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {AppBar, Box, Button, Container, Toolbar, Typography} from '@mui/material';
import {slide as Burger } from 'react-burger-menu';
import '../../styles/Menu.css';
import {useAuth} from "../auth/AuthProvider";
import {ADMIN, INSTRUCTOR, Role, RoleOptions, STUDENT} from "../../utils/types/account.types";
import {UserData} from "../../utils/types/auth.types";
import MoleculeState from "../molecule/MoleculeState";
import {getRole} from "../../utils/helpers/accountHelper";

function Menu(props: {showLinks: boolean}) {
    let auth = useAuth();

    let [links, setLinks] = useState([<Button key={0} onClick={auth.logout}>Logout</Button>]);

    useEffect(() => {
        getRole(auth.userData.jwt).then((role: string) => {
            let newLinks = [];
            let n = 0;
            if (role === ADMIN ) newLinks.push( <Button key={n++} component={Link} to={'/manage_users'}>Users</Button> );
            if (role === STUDENT || role === INSTRUCTOR ) newLinks.push( <Button key={n++} component={Link} to={'/student_ar_view'}>AR View</Button>);
            if (role === INSTRUCTOR ) newLinks.push( <Button key={n++} component={Link} to={'/view_molecule'}>Molecules</Button>);
            if (role === INSTRUCTOR ) newLinks.push( <Button key={n++} component={Link} to={'/lesson_plans'}>Lesson Plans</Button>);
            if (role === INSTRUCTOR ) newLinks.push( <Button key={n++} component={Link} to={'/environmental_factors'}>Environmental Factors</Button>);
            if (role === INSTRUCTOR ) newLinks.push( <Button key={n++} component={Link} to={'/environmental_factor_tag'}>Environmental Factor Tags</Button> );
            newLinks.push(<Button key={n++} onClick={auth.logout}>Logout</Button>);
            setLinks(newLinks);
        });
    }, [auth]);

    return (
        <Box>
            <AppBar id={'NavWrapper'} position='static'>
                <Container>
                    <Toolbar disableGutters sx={{ justifyContent: 'space-between', py: 2 }}>
                        <Button component={Link} to={'/'} sx={{color: 'white' }}>
                            <Typography variant='h6'>AR Protein Dynamics</Typography>
                        </Button>

                        {props.showLinks &&
                            <Box>
                                <Box sx={{display: { xs: 'none', lg: 'flex' } }}>
                                    {links.map((Link) => (Link))}
                                </Box>

                                <Box sx={{display: { lg: 'none' } }}>
                                    <Burger right noOverlay disableAutoFocus>
                                        {links.map((Link) => (Link))}
                                    </Burger>
                                </Box>
                            </Box>
                        }
                        {!props.showLinks && <Button onClick={auth.logout}>Logout</Button>}
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
}

export default Menu;
