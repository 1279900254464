export const ADMIN = 'Administrator';
export const INSTRUCTOR = 'Instructor';
export const STUDENT = 'Student';

/**
 * Enum containing the various roles a user can have.
 */
export enum Role {
    ADMIN = 'ROLE_ADMIN',
    INSTRUCTOR = 'ROLE_INSTRUCTOR',
    STUDENT = 'ROLE_STUDENT'
}

/**
 * Interface containing the fields for a user account.
 */
export interface Account {
    id: number;
    username: string;
    passwordPlain: string;
    firstName: string;
    lastName: string;
    role: Role;
}

/**
 * Array of possible role display types.
 */
export const RoleOptions = [
    { label: ADMIN, value: 'ROLE_ADMIN' },
    { label: INSTRUCTOR, value: 'ROLE_INSTRUCTOR' },
    { label: STUDENT, value: 'ROLE_STUDENT' }
];
