import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

/**
 * React component that functions as an auth guard. If the user isn't authenticated then the
 * component routes them to the login page.
 * 
 * @param children JSX elements that require authentication to be accessed
 */
export function ProtectedRoute({ children }: { children: JSX.Element }) {
    // We save their current location so that after the user logs in, we can push them back to the
    // page that they were originally on
    const auth = useAuth();
    const location = useLocation();

    // If the user has no user data, they are not authenciated -> so route to '/login'
    if (auth.userData == undefined) {
        return <Navigate to='/login' state={{ from: location }} replace />;
    }

    // Otherwise (user is logged in), return children elements
    return children;
}
