import {Box, IconButton} from "@mui/material";
import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import CopyIcon from "@mui/icons-material/FileCopy";
import {useConfirm} from "material-ui-confirm";

function Toolbar(props: {id: number, message: string, edit, delete, openSharing?, copy?}) {
    const confirm = useConfirm();
    const handleClick = () => {
        confirm({   description: props.message,
            confirmationText: 'Delete' })
            .then(() => { props.delete(); })
            .catch(() => {});
    };
    
    if ( props.id < 0 ) return null;
    return (
        <Box className="flexColumn" sx={{justifyContent: 'space-evenly'}}>
            <IconButton color="success" onClick={props.edit}><SaveIcon/></IconButton>
            <IconButton color="error" onClick={handleClick}><DeleteIcon/></IconButton>
            <IconButton color="primary" onClick={props.openSharing}><ShareIcon/></IconButton>
            <IconButton color="primary" onClick={props.copy}><CopyIcon/></IconButton>
        </Box>
    );
}
export default Toolbar;