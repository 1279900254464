import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import { VariantType, useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import '../../App.css';
import { deleteMolecule, getMolecule, postMolecule, putMolecule } from "../../utils/helpers/moleculeHelper";
import { DEFAULT_FACTOR_TRIGGER, DEFAULT_MOLECULE, DEFAULT_STATE, Molecule, State } from '../../utils/types/molecule.types';
import FormLabel from "../utils/FormLabel";
import SubmitButton from "../utils/SubmitButton";
import Toolbar from "../utils/Toolbar";
import MoleculeTag from "./MoleculeTag";
import MoleculeState from "./MoleculeState";


function MoleculeDetails(props: {molecule: Molecule, updateMolecules}) {
    const [molecule, setMolecule] = useState<Molecule>(props.molecule);
    const { enqueueSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    const updateMoleculeData = (e: ChangeEvent<HTMLInputElement>) => {
        setMolecule(prevMolecule => ({
            ...prevMolecule,  // Spread the previous state to create a shallow copy
            [e.target.name]: e.target.value  // Update the specific field by its name
        }));
    }

    function addState() {
        setMolecule(prevMolecule => ({
            ...prevMolecule,  // Shallow copy of molecule
            states: [...prevMolecule.states, DEFAULT_STATE()]  // Add new state to a copy of the array
        }));
    }

    function updateMoleculeState(index: number, moleculeState: State) {
        setMolecule(prevMolecule => {
            const updatedStates = [...prevMolecule.states];  // Create a shallow copy of the states array
    
            if (index < 0) {
                // Update defaultState
                return {
                    ...prevMolecule,  // Shallow copy of molecule
                    defaultState: { ...moleculeState }  // Deep copy of defaultState
                };
            } else {
                updatedStates[index] = { ...moleculeState };  // Deep copy of the state being updated
                return {
                    ...prevMolecule,  // Shallow copy of molecule
                    states: updatedStates  // Updated states array
                };
            }
        });
    }

    function deleteMoleculeState(index: number) {
        if (index < 0) return;  // Do nothing if index is invalid
    
        setMolecule(prevMolecule => ({
            ...prevMolecule,  // Shallow copy of molecule
            states: prevMolecule.states.filter((_, i) => i !== index)  // Create new array without the deleted state
        }));
    }

    /*
    function addEnvironmentalFactorTrigger(stateId: number) {
        let states = moleculeStates.slice();
        let stateOfInterest = states[stateId];
        stateOfInterest.environmentalFactorTriggers.push(DEFAULT_FACTOR_TRIGGER());
        states[stateId] = stateOfInterest;
        setMoleculeStates(states);
    }

    function deleteEnvironmentalFactorTrigger(stateId: number, index: number) {
        let states = moleculeStates.slice();
        let stateOfInterest = states[stateId];
        stateOfInterest.environmentalFactorTriggers.splice(index, 1);
        states[stateId] = stateOfInterest;
        setMoleculeStates(states);
    }
    */

    function addMolecule() {
        postMolecule( molecule ).then((res) => {
            handleClickVariant("Molecule successfully created.", 'success');
            
            props.updateMolecules( res.id );
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function editMolecule() {
        putMolecule(props.molecule.id, molecule).then((res) =>{
            handleClickVariant("Molecule successfully saved", 'success');
            props.updateMolecules( res.id );
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function saveMolecule() {
        if (props.molecule.id !== null) {
            editMolecule();
        } else {
            addMolecule();
        }
    }

    function getMoleculeData(id: number) {
        getMolecule(id).then((moleculeData) => {
            setMolecule(moleculeData);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
            throw error;
        });
    }

    function removeMolecule() {
        deleteMolecule( props.molecule.id ).then(() => {
            props.updateMolecules();
            handleClickVariant("Molecule Successfully Deleted", 'success');
        }).catch(function (error) {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {
        if ( props.molecule.id === null ) {
            setMolecule(DEFAULT_MOLECULE());
        } else {
            getMoleculeData(props.molecule.id);
        }
    }, [props.molecule.id]);

    return (
        <Box sx={{flexBasis: '600px', p: 2}}>
            {/* This FormLabel is not from MUI. It is responsible for putting "Add " or "Edit ". */}
            <FormLabel id={props.molecule.id} message={'Molecule'}/>
            <FormControl>
                <Box sx={{display: 'flex', mb: 2}}>
                    <Box className="flexColumn" sx={{width: '100%'}}>
                        <TextField required label="Name" name="name" variant="standard"
                                   onChange={updateMoleculeData} value={molecule.name} />
                        <TextField label="Description" variant="standard" name="description"
                                   onChange={updateMoleculeData} value={molecule.description} />
                    </Box>
                    <Toolbar delete={removeMolecule} edit={saveMolecule} id={props.molecule.id}
                             message={'Contact an administrator to restore this molecule.'}/>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                    <Typography sx={{fontWeight: '500', fontSize: 20}}>Molecule States</Typography>
                    <IconButton aria-label="add" color="primary" onClick={addState}><AddIcon/></IconButton>
                </Box>
                <Box className="flexColumn" sx={{justifyContent: 'flex-start', mb: 1}}>
                    {/* Dropdown panel for base state of molecule*/}
                    <MoleculeState index={-1} state={molecule.defaultState} setState={updateMoleculeState}
                            deleteState={deleteMoleculeState}/>
                    {molecule.states.map((state, index) => (
                        <MoleculeState index={index} state={state} setState={updateMoleculeState}
                                deleteState={deleteMoleculeState} key={index}/>))}
                </Box>
                { molecule.id != null && <MoleculeTag molecule={molecule} setMolecule={setMolecule} /> }
                <SubmitButton id={props.molecule.id} submitHandler={addMolecule} message={'Submit new Molecule'} />
            </FormControl>
        </Box>
    );
}

export default MoleculeDetails;