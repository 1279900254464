import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import { ARMarker } from "../types/util.types";

export async function getARTagForMolecule(moleculeId: number) {
    let tag: ARMarker;
    await baseAxiosInstance.get("/markers/molecule/" + moleculeId).then((response) => {
        tag = response.data;
    }).catch((error) => {throw error});
    return tag;
}

export async function getARTagForFactorTrigger(triggerId: number) {
    let tag: ARMarker;
    await baseAxiosInstance.get("/markers/trigger/" + triggerId).then((response) => {
        tag = response.data;
    }).catch((error) => {throw error});
    return tag;
}

export async function addARTagForMolecule(moleculeId: number) {
    let dto = {
        molecule: moleculeId
    }
    let tag: ARMarker;
    await baseAxiosInstance.post("/markers", dto).then((response) => {
        tag = response.data;
    }).catch((error) => {throw error});
    return tag;
}

export async function addARTagForFactorTrigger(triggerId: number) {
    let dto = {
        arFactorTrigger: triggerId
    }
    let tag: ARMarker;
    await baseAxiosInstance.post("/markers", dto).then((response) => {
        tag = response.data;
    }).catch((error) => {throw error});
    return tag;
}