export function ActiveCondition(molecule) {
    
    //Fields Set when JSON is parsed
    this.factorIds = [];
    this.exclusive = false;
    
    
    this.evaluate = function(molecule) {
		//return true;
		//factorIds is subset, moleculeFactors is superset	
		var activeFactors = molecule.getActiveFactorIds();

		if (this.exclusive) {
			//See if the arrays are the same size
			if (activeFactors.length !== this.factorIds.length) {
				//If they aren't return false
				return false;
			}
		}

		// See if every element in factorId's is in activeFactors
		return this.factorIds.every(function (value) {
			return (activeFactors.indexOf(value) >= 0);
		});    
    }
}
