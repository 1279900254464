import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, IconButton, List, ListItemButton, Typography } from "@mui/material";
import LessonPlanDetails from "../components/lesson_plans/LessonPlanDetails";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useConfirm } from "material-ui-confirm";
import { DEFAULT_LESSON_PLAN, LessonPlan } from "../utils/types/lessonPlan.types";
import { ADD_LESSON_PLAN } from "../utils/constants/lessonPlanConstants";
import { getLessonPlans } from '../utils/helpers/lessonPlanHelper';
import { getCategories } from '../utils/helpers/categoryHelper';
import { Category } from "../utils/types/category.types";

/**
 * ViewLessonPlans function renders the lesson plans page 
 */
function ViewLessonPlans() {
    // React hooks for model variables. You can call `setX` to set a given variable
    const [rows, setRows] = useState<LessonPlan[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [lessonPlan, setLessonPlan] = useState<LessonPlan>(DEFAULT_LESSON_PLAN());
    let [lessonPlanId, setLessonPlanId] = useState(ADD_LESSON_PLAN);

    // Refresh the lesson plans and categories in the view
    function refreshView(id: number) {
        // Get lesson plans from the lesson plan helper
        getLessonPlans().then((lessonPlans: LessonPlan[]) => {
            // Set the rows of the lesson plan table to the received values
            setRows(lessonPlans);

            if (lessonPlans.length === 0 || id == undefined) {
                // If there are no lesson plans or the id is undefined, set the lesson plan to the default lesson plan
                setLessonPlan(DEFAULT_LESSON_PLAN());
            } else {
                // Set the lesson plan to the lesson plan with the given id
                const currentLessonPlan = lessonPlans.find((lessonPlan) => lessonPlan.id === id);
                if (currentLessonPlan != undefined) {
                    setLessonPlan(currentLessonPlan);
                } else {
                    // If lesson plan with the given id does not exist, set the lesson plan to the first lesson plan
                    setLessonPlan(lessonPlans[0]);
                }
            }
        });

        // Get categories from the category helper and then set the categories to the received values
        getCategories().then((categoriesFromAPI: Category[]) => {
            setCategories(categoriesFromAPI);
        });
    }

    // Initialize the view
    useEffect(() => { refreshView(null) }, []);

    // Return the React template
    return (
        <Box className="flexColumn" sx={{ flex: '1' }}>
            <Container id="lessonPlanPanel" sx={{ display: 'flex', backgroundColor: 'white', height: '100%' }}>
                {/* Left Column */}
                <Box sx={{ boxShadow: '1px 0px rgb(0 0 0 / 10%)', flexBasis: '250px', maxWidth: '250px' }} >
                    <Box className="lessonPlansLabel" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                        <Typography sx={{ fontWeight: '500', fontSize: 20 }}>Lesson Plans</Typography>
                        <IconButton aria-label="add" onClick={() => { setLessonPlanId(null); setLessonPlan(DEFAULT_LESSON_PLAN()); }}>
                            <AddIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingRight: 3 }}>
                        {categories.filter(category => category.lessonPlans.length !== 0).map((category, index) => (
                            <Accordion style={{ width: '100%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }} key={index.toString()}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography sx={{
                                        overflow: 'hidden',
                                        maxWidth: '100%',
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-all'
                                    }}>{category.name}</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <List>
                                        {category.lessonPlans.map((row, index) => (
                                            <ListItemButton key={index} selected={lessonPlan.id === row.id} sx={{
                                                px: 2,
                                                borderBottom: (index !== category.lessonPlans.length - 1) ? '1px solid rgba(0, 0, 0, 0.12)' : '',
                                                py: (index !== category.lessonPlans.length - 1) ? 2 : 1,
                                                overflow: 'hidden',
                                                maxWidth: '100%',
                                                wordWrap: 'break-word',
                                                wordBreak: 'break-all'
                                            }} onClick={() => setLessonPlan(row)}>
                                                <Typography>{row.name}</Typography>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Box>

                {/* Right Column */}
                <Box sx={{ flexBasis: '900px' }}>
                    <LessonPlanDetails lessonPlan={lessonPlan} updateLessonPlans={refreshView} categories={categories} />
                </Box>
            </Container>
        </Box>

    );
}
export default ViewLessonPlans;