import cloneDeep from "lodash.clonedeep";

export const RESPONSE_URL = "/response";
export const RESPONSES_URL = '/responses';
export const RESPONSES_CSV_URL = '/csv/lessonPlan';

export const DEFAULT_RESPONSE_DATA = () => {
    return cloneDeep({
        id: null,
        response: "",
        choiceId: null,
        image: null,
        accountId: null,
        accountUsername: "",
        questionId: null,
        isCorrect: false
    });
}