import React, {ChangeEvent} from "react";
import {Switch, TableCell, TableRow, TextField} from "@mui/material";
import { QuestionChoice } from "../../utils/types/lessonPlan.types";

function LessonPlanQuestionChoice(props: {index: number, questionChoice: QuestionChoice, updateChoice}) {

    const updateChoice = (e: ChangeEvent<HTMLInputElement>) => {
        let data = {...props.questionChoice};
        data[e.target.name] = e.target.value;
        props.updateChoice( props.index, data );
    }

    const updateSwitch = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("Switch updated. Event and event target (correctChoice): ", e, e.target.checked)
        let data = {...props.questionChoice};
        data[e.target.name] = !data[e.target.name];
        props.updateChoice( props.index, data );
    }

    return (
        <TableRow>
            <TableCell scope="row">{String.fromCharCode(65 + props.index)}</TableCell>
            <TableCell>
                <TextField name="choiceText" variant="standard" multiline maxRows={2} onChange={updateChoice}
                           value={ props.questionChoice.choiceText } sx={{width: '100%'}}/>
            </TableCell>
            <TableCell>
                <Switch name="correctChoice" onChange={updateSwitch} checked={props.questionChoice.correctChoice} />
            </TableCell>
        </TableRow>
    );
}
export default LessonPlanQuestionChoice;