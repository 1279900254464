import React, { useEffect, useState } from 'react';
import { getImage } from '../utils/helpers/imageHelper';

const StudentImage = ({ lessonPlanId, questionId, username }) => {
    const [imageData, setImageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        getImage(lessonPlanId, questionId, username).then((image) => {
            // Remove duplicate prefix
            const correctedImageData = image.split(',')[1];
            setImageData(correctedImageData);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            setError(true);
        });
    }, [lessonPlanId, questionId, username]);

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {loading ? (
                <h1>Loading image...</h1>
            ) : ( error ? (
                <h1>Error loading image</h1>
                ) : (
                    <img
                        src={`data:image/png;base64,${imageData}`}
                        alt="Image"
                        style={{ maxWidth: '100%', maxHeight: '100vh' }}
                    />
                )
            )}
        </div>
    );
};

export default StudentImage;
