import React from 'react';
import {IconButton, Container, Typography} from '@mui/material';
import {DeleteRounded} from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { baseAxiosInstance } from '../utils/services/BaseAxiosInstance.service';
import { AccountTypes } from '../utils/types';
import { AccountHelper } from '../utils/helpers';
import {useSnackbar, VariantType} from "notistack";

export default function ManageUsers() {
    // State object and setter for the application's users.
    const [users, setUsers] = React.useState([]);
    // State object and setter for triggering a refetch of users' data.
    const [userDataUpToDate, setUserDataUpToDate] = React.useState(true);

    const onRowEditComplete = (e: any) => {
        let modifiedUsers = [...users];
        let { newData, index } = e;
        modifiedUsers[index] = newData;
        let account = {
            firstName: newData.firstName,
            lastName: newData.lastName,
            username: newData.username,
            role: newData.role,
            id: users[index].id,
            passwordPlain: null
        };
        submitEditUser(account, modifiedUsers);
    };

    const textEditor = (options: any) => {
        return (
            <InputText type='text' value={options.value} onChange={(e: any) => options.editorCallback(e.target.value)}/>
        );
    };

    const statusEditor = (options: any) => {
        return (
            <Dropdown value={options.value} options={AccountTypes.RoleOptions} optionLabel='label' optionValue='value'
                onChange={(e: any) => options.editorCallback(e.value)} placeholder='Select a Role'
                itemTemplate={(option: any) => {
                    return (
                        <span className={`product-badge status-${option.value}`}>{option.label}</span>
                    );
                }}/>
        );
    };

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    // useEffect fires on initial page render and fetches users from the DB.
    React.useEffect(() => {getUsers();}, []);

    // useEffect fires on changes to userDataUpToDate state.
    React.useEffect(() => {
        if (!userDataUpToDate) {getUsers();}
    }, [userDataUpToDate]);

    // Sends a GET request to the API to retrieve a list of all users and their information from the DB.
    function getUsers() {
        baseAxiosInstance.get('users').then((response: any) => {
            setUsers(response.data);
            setUserDataUpToDate(true);
        }).catch(() => {
            handleClickVariant("Error: Couldn't Populate Users", 'error');
        });
    }

    // Sends a PUT request to the API to modify an existing user's information.
    function submitEditUser(account: AccountTypes.Account, modifiedUsers: any) {
        baseAxiosInstance.put(`users/${account.id}`, account).then((response) => {
            setUserDataUpToDate(false);
            setUsers(modifiedUsers);
            handleClickVariant(`User ${response.data.firstName} was successfully edited.`, 'success');
        }).catch((e) => {
            console.log(e);
            handleClickVariant(`Error editing user ${account.username}.`, 'error');
        });
    }

    // Sends a DELETE request to the API to remove a user from the DB by id.
    function deleteUser(id: number, username: string) {
        baseAxiosInstance.delete(`users/${id}`).then(() => {
            setUserDataUpToDate(false);
            handleClickVariant(`${username} was successfully deleted.`, 'success');
        }).catch(() => {
            handleClickVariant(`Could not delete ${username}.`, 'error');
        });
    }

    return (
            <Container id="moleculePanel" className="flexColumn" sx={{flex: '1', display: 'flex', backgroundColor: 'white', height: '100%'}}>
                <Typography sx={{fontWeight: '500', fontSize: 20, mt: 2, mb: 1}}>Manage Users</Typography>
                <DataTable value={users} editMode='row' dataKey='id' onRowEditComplete={onRowEditComplete} responsiveLayout='scroll'>
                    <Column field='firstName' header='First Name' editor={(options: any) => textEditor(options)}/>
                    <Column field='lastName' header='Last Name' editor={(options: any) => textEditor(options)}/>
                    <Column field='username' header='Username' editor={(options: any) => textEditor(options)}/>
                    <Column field='role' header='Role' body={(e: any) => AccountHelper.formatRole(e.role)} editor={(options: any) => statusEditor(options)}/>
                    <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}/>
                    <Column body={(rowData: any) => (<IconButton onClick={() => deleteUser(rowData.id, rowData.username)}><DeleteRounded /></IconButton>)}exportable={false}/>
                </DataTable>
            </Container>
    );
}
