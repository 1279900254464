import axios, { AxiosResponse } from 'axios';
import { environment } from '../../environment';

// We define the API endpoints here for convenience using the provided environment
const API_AUTHENTICATE = environment.api + '/authenticate';
const API_VALIDATE = environment.api + '/validateToken';
const API_REGISTER = environment.api + '/users';

/**
 * APIService handles API calls that can be made anonymously. In other words,
 * you do not need a verified JWT token to make these calls. Such calls include
 * logging in (validating the token and authenticating) and registering a new user.
 * 
 * @author Ethan Baker
 */
const APIService = {
    /** Log a given user in with a provided name and password */
    login(username: String, password: String): Promise<string> {
        console.log(`[INFO]: logging in with username: ${username}`);

        // We return a promise to handle the asynchronous nature of the API call
        return new Promise((resolve, reject) => {
            // Make a POST request to the authenticate endpoint
            axios.post(API_AUTHENTICATE, {"username": username, "password": password})
                .then((response: AxiosResponse) => {
                    if (response.data != undefined && response.data.token != undefined) {
                        console.log(`[INFO]: successfully logged in and got token`);

                        // Parse the response to resolve with the token (if it exists)
                        const token: string = response.data.token;
                        resolve(token);
                    } else {
                        console.error(`[ERR]: no data sent from backend`);

                        // The token doesn't exist, so reject
                        reject('No data was sent back');
                    }
                }).catch((err) => {
                    console.error(`[ERR]: error with login endpoint (${err})`);

                    // The request failed, so reject
                    reject("Invalid Username or Password") 
                });
        });
    },

    /** Register a new user with a provided name, username, and password */
    register(firstName: string, lastName: string, username: string, password: string): Promise<any> {
        console.info(`[INFO]: registering new user with username: ${username}`);

        // We return a promise to handle the asynchronous nature of the API call
        return new Promise((resolve, reject) => {
            // Make a POST request to the users endpoint
            axios.post(API_REGISTER, {
                "firstName": firstName,
                "lastName": lastName,
                "username": username,
                "passwordPlain": password
            }).then((response: AxiosResponse) => {
                console.log(`[INFO]: successfully registered new user`);

                // If the data exists, resolve with it. Otherwise reject
                response.data != undefined ? resolve(response.data) : reject('Error registering new user.');
            }).catch((err) => {
                console.log(`[ERR]: error with register endpoint (${err})`);

                // The request failed, so reject
                reject("Error Registering User")
            });
        });
    },

    /** Validate a provided JWT token using the backend as the source of truth */
    validate(token: string): Promise<boolean> {
        console.log(`[INFO]: validating token`);

        // We return a promise to handle the asynchronous nature of the API call
        return new Promise((resolve, reject) => {
            // Make a POST request to the validateToken endpoint
            axios.post(API_VALIDATE, token)
                .then((response: AxiosResponse) => resolve(response.data)) // This endpoint can only return true or false, so resolve with the value
                .catch((error) => reject(error)); // And reject on any error
        });
    }
};

// Export the service. This should be the only export from this file
export default APIService;