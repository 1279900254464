import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, FormControl, IconButton, TextField, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {DEFAULT_LESSON_PLAN, DEFAULT_QUESTION, LessonPlan, Question} from "../../utils/types/lessonPlan.types";
import {useSnackbar, VariantType} from "notistack";
import SubmitButton from "../utils/SubmitButton";
import Toolbar from "../utils/ToolbarWithShare";
import LessonPlanTabControl from "./LessonPlanTabControl";
import LessonPlanResponse from "./LessonPlanResponse";
import LessonPlanQuestion from "./LessonPlanQuestion";
import {getLessonPlan, postLessonPlan, putLessonPlan, deleteLessonPlan,getLessonPlanAccess, getInstructors, saveLessonPlanAccess, copyLessonPlan} from "../../utils/helpers/lessonPlanHelper";
import {postCategory, addToCategory, removeFromCategory, getCategories} from "../../utils/helpers/categoryHelper";
import {ADD_LESSON_PLAN, DEFAULT_LESSON_PLAN_DATA, DEFAULT_QUESTION_AND_CHOICE_DATA} from "../../utils/constants/lessonPlanConstants";
import LessonPlanSession from "./LessonPlanSession";
import FormLabel from "../utils/FormLabel";
import LessonPlanAccessPopup from './LessonPlanAccessPopup';
import { Category } from "../../utils/types/category.types";
import Autocomplete from '@mui/material/Autocomplete';

interface Instructor {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    role: string;
}

function LessonPlanDetails(props: {lessonPlan: LessonPlan, updateLessonPlans, categories: Category[]}) {
    const [lessonPlanData, setLessonPlanData] = useState<LessonPlan>(DEFAULT_LESSON_PLAN());
    const [showResponses, setShowResponses] = useState<boolean>(false);

    // Sharing popup
    const [isAccessPopupOpen, setIsAccessPopupOpen] = useState(false);

    // Category of the lesson plan - blank if Uncategorized
    const [category, setCategory] = useState('');
    const handleChange = (event: React.SyntheticEvent, newValue: boolean) => {setShowResponses(newValue);};
    const {enqueueSnackbar} = useSnackbar();
    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    const updateLessonPlanData = (e: ChangeEvent<HTMLInputElement>) => {
        let data = {...lessonPlanData};
        data[e.target.name] = e.target.value;
        setLessonPlanData(data);
    }

    const updateQuestionAndChoicesData = (index: number, lessonPlanQuestionAndChoices: Question) => {
        let data = {...lessonPlanData};
        data.questions[index] = lessonPlanQuestionAndChoices;
        setLessonPlanData(data);
    }

    function addQuestion() {
        let data = {...lessonPlanData};
        data.questions.push(DEFAULT_QUESTION());
        setLessonPlanData(data);
    }

    const deleteQuestionAndChoices = (index: number) => {
        // Update the frontend questions list
        let data = {...lessonPlanData};
        data.questions.splice(index, 1);
        setLessonPlanData(data);
    }

    const reorderQuestionAndChoices = (index: number, indexTo: number) => {
        let data = {...lessonPlanData};
        let questionsAndChoices = data.questions;
        if ( indexTo >= 0 && indexTo < questionsAndChoices.length ) {
            const element = questionsAndChoices[index];
            questionsAndChoices.splice(index, 1);
            questionsAndChoices.splice(indexTo, 0, element);
            questionsAndChoices.forEach((question, index) => {question.number = index + 1;})
            data.questions = questionsAndChoices;
            setLessonPlanData(data);
        }
    }

    async function editLessonPlan() {
        let lessonPlanId = lessonPlanData.id;
        for (let i = 0; i < lessonPlanData.questions.length; i++) {
            lessonPlanData.questions[i].number = i + 1;
        }
        if (lessonPlanData.id === null) {
            await postLessonPlan(lessonPlanData).then(newLessonPlanId => {
                lessonPlanId = newLessonPlanId.id;
                handleClickVariant("Successfully Created Lesson Plan", 'success');
            }).catch(function (error) {
                handleClickVariant(error.response.data.message, 'error');
            });
        } else {
            await putLessonPlan(props.lessonPlan.id, lessonPlanData).then(lessonPlanResponse => {
                lessonPlanId = lessonPlanResponse.id;
                handleClickVariant("Successfully Updated Lesson Plan", 'success');
            }).catch(function (error) {
                handleClickVariant(error.response.data.message, 'error');
            });
        }

        // Update the category in the backend
        if (category !== '') {
            // Check if category already exists
            let updatedCategoryId = -1;
            for (let i = 0; i < props.categories.length; i++) {
                if (props.categories[i].name === category && props.categories[i].name !== "Uncategorized") {
                    updatedCategoryId = props.categories[i].id;
                    break;
                }
            }

            // Get the current category the lesson plan is in
            let currentCategory = props.categories.find(category => category.lessonPlans.find(plan => plan.id === lessonPlanId));

            // If in a category and still is (don't do anything) or if not in a category and still isn't (don't do anything)
            if ((currentCategory !== undefined && currentCategory.name === category) || (currentCategory === undefined && (category === "Uncategorized" || category === ''))) {
                props.updateLessonPlans(lessonPlanId);
                return;
            }

            // If in a category but now isn't in the same category (remove it from the old category)
            if (currentCategory !== undefined && category !== currentCategory.name && currentCategory.name !== "Uncategorized") {
                // Remove from category
                await removeFromCategory(currentCategory.id, lessonPlanId).catch(function (error) {
                    handleClickVariant("Error removing from old category", 'error');
                });
            }

            // If not adding to a new category (do nothing)
            if (category === "Uncategorized" || category === '') {
                props.updateLessonPlans(lessonPlanId);
                return;
            }

            // If adding to a new category, check to make sure it exists, otherwise create it
            if (updatedCategoryId === -1) {
                await postCategory(category).then((categoryAPI) => {
                    updatedCategoryId = categoryAPI.id;
                }).catch(function (error) {
                    handleClickVariant("Error making the category", 'error');
                });
            }

            // Now add it to the updated category
            await addToCategory(updatedCategoryId, lessonPlanId).catch(function (error) {
                handleClickVariant('Error adding to the category', 'error');
            });

            props.updateLessonPlans(lessonPlanId);
        }
    }

    function getLessonPlanData() {
        getLessonPlan( props.lessonPlan.id ).then(function(response){
            let reordered = response as LessonPlan;
            let oldQuestions = reordered.questions;
            reordered.questions = [];
            for (let i = 0; i < oldQuestions.length; i++) {
                reordered.questions.splice(oldQuestions[i].number - 1, 0, oldQuestions[i]);
            }
            setLessonPlanData(reordered);
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function removeLessonPlan() {
        deleteLessonPlan( props.lessonPlan.id ).then(function(){
            props.lessonPlan.id = null;
            props.updateLessonPlans();
            handleClickVariant("Successfully deleted Lesson Plan", 'success');
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function openSharing() {
        getLessonPlanAccess( props.lessonPlan.id ).then(function(response){
            // turn object into json
            setIsAccessPopupOpen(true);
            // alert("Access response: " + JSON.stringify(response));
            console.log(response);
            return response;
            // setIsAccessPopupOpen(true);
            // window.open(response, "_blank");
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function getAllInstructors(): Promise<Instructor[]> {
        return getInstructors().then(function(response){
            console.log("instructors: ", response);
            return response;
        }).catch(function(error){
            console.log('error', error.response.data.message);
            return []; // Return an empty array in case of error
        });
    }

    // saveAccessData: (id: number, accessData: Map<string, InstructorAccessSharing[]>) => Promise<void>;
    function saveAccessData(id: number, accessData: Map<string, Instructor[]>) {
        console.log("saveAccessData", id, accessData);
        return saveLessonPlanAccess(id, accessData).then(function(response){
            console.log("saveAccessData response: ", response);
            return response;
        }).catch(function(error){
            console.log('error', error.response.data.message);
            return error.response.data.message; // Return an empty array in case of error
        });
    }

    // Copy lesson plan
    function copyLP() {
        copyLessonPlan(props.lessonPlan.id).then(function(response){
            props.updateLessonPlans(response.id);
            handleClickVariant("Successfully copied Lesson Plan", 'success');
        }).catch(function(error){
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {
        // Update category when lessonPlanData changes
        const currentCategory = props.categories.find(category => category.lessonPlans.find(plan => plan.id === lessonPlanData.id));
        if (currentCategory && currentCategory.name !== "Uncategorized") {
            setCategory(currentCategory.name);
        } else {
            setCategory('');
        }
    }, [lessonPlanData.id]);

    useEffect(() => {
        if ( props.lessonPlan.id !== null ) {
            getLessonPlanData();
            //getQuestionsAndChoicesForLessonPlan();
        } else {
            setLessonPlanData(DEFAULT_LESSON_PLAN());
        }
    }, [props]);

    return (
        <Box sx={{flexBasis: '600px', p: 2}}>
            <FormLabel id={props.lessonPlan.id} message={'Lesson Plan'}/>
            { props.lessonPlan.id !== null && <LessonPlanTabControl lessonPlanId={props.lessonPlan.id} showResponses={showResponses} handleChange={handleChange} /> }
            { props.lessonPlan.id !== null && showResponses &&
                <LessonPlanResponse lessonPlanId={props.lessonPlan.id} lessonPlanDataName={lessonPlanData.name} lessonPlanQuestionsAndChoices={lessonPlanData.questions}/>
            }
            { !showResponses &&
                <FormControl variant={"outlined"} sx={{width: '100%'}}>
                    <Box sx={{display: 'flex', mb: 1}}>
                        <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', mb: 1}}>
                            <Box className="flexColumn" component={"span"} sx={{width: '100%', mb: 1}}>
                                <TextField required id="filled-basic" label="Name" name="name" variant="standard" multiline
                                           maxRows={2} onChange={updateLessonPlanData} value={lessonPlanData.name}/>
                            </Box>
                            <Box component={"span"} sx={{width: '100%', mb: 1}}>
                                <Autocomplete
                                    options={[
                                        // Place Uncategorized category at the beginning
                                        ...props.categories.filter(category => category.name === "Uncategorized"),
                                        // Other categories except Uncategorized
                                        ...props.categories.filter(category => category.name !== "Uncategorized" && category.lessonPlans.length !== 0)
                                    ].map(category => category.name)}
                                    fullWidth
                                    freeSolo
                                    value={category}
                                    onChange={(e, newValue) => {
                                        setCategory(newValue || '');
                                    }}
                                    onInputChange={(e, newValue) => {
                                        setCategory(newValue || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Category"
                                            name="category"
                                            placeholder="Search for and choose an existing category or enter a new one"
                                            variant="standard"
                                            required
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        <Toolbar id={props.lessonPlan.id} edit={editLessonPlan} delete={removeLessonPlan} openSharing={openSharing} copy={copyLP} message={'Deleting this lesson plan is a permanent action that cannot be undone?'}/>
                    </Box>

                    { props.lessonPlan.id !== null && <LessonPlanSession lessonPlanId={props.lessonPlan.id} /> }

                    { props.lessonPlan.id !== null && <LessonPlanAccessPopup
                        open={isAccessPopupOpen}
                        onClose={() => setIsAccessPopupOpen(false)}
                        id={props.lessonPlan.id}
                        // @ts-ignore
                        fetchAccessData={getLessonPlanAccess}
                        fetchAllInstructors={getAllInstructors}
                        saveAccessData={saveAccessData}
                        handleClickVariant={handleClickVariant}
                    /> }

                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                        <Typography sx={{fontWeight: '500', fontSize: 20}}>Questions</Typography>
                        <IconButton aria-label="add" color="primary" onClick={addQuestion}><AddIcon/></IconButton>
                    </Box>

                    <Box className="flexColumn" sx={{mb: 1}}>
                        {lessonPlanData.questions.map((question, index) => (
                            <LessonPlanQuestion key={index} index={index} question={question}
                                                updateQuestionAndChoices={updateQuestionAndChoicesData}
                                                reorderQuestionAndChoices={reorderQuestionAndChoices}
                                                deleteQuestionAndChoices={deleteQuestionAndChoices}/>
                        ))}
                    </Box>

                    <SubmitButton id={props.lessonPlan.id} submitHandler={editLessonPlan} message={'Submit new Lesson Plan'}/>

                </FormControl>
            }
        </Box>
    );
}

export default LessonPlanDetails;