import { baseAxiosInstance } from "../services/BaseAxiosInstance.service";
import { LessonPlan } from "../types/lessonPlan.types";

interface Instructor {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    role: string;
}

interface InstructorAPI {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    role: string;
}

export async function getLessonPlan(id: number) {
    let lessonPlan: LessonPlan;
    await baseAxiosInstance.get( "/lessonplans/" + id).then( async (response) => {
        lessonPlan = response.data;
    }).catch((error) => {throw error});
    return lessonPlan;
}

// Get sharing access for lesson plans
export async function getLessonPlanAccess(id: number) {
    let access: string;
    await baseAxiosInstance.get( "/lessonplans/" + id + "/access").then( async (response) => {
        access = response.data;
    }).catch((error) => {throw error});
    return access;
}

// Copy lesson plan for lesson plans
export async function copyLessonPlan(id: number) {
    let newPlan: LessonPlan;
    await baseAxiosInstance.post( "/lessonplans/" + id + "/copy").then((response) => {
        newPlan = response.data;
    }).catch((error) => {throw error});
    return newPlan;
}

// Save sharing access for lesson plans
export async function saveLessonPlanAccess(id: number, access: Map<string, Instructor[]>) {
    // convert map to json not using stringify
    // map should be an array of InstructorAPI objects with key being owner, editors, or viewers
    let accessJson = {};
    access.forEach((value, key) => {
        let instructors: InstructorAPI[] = [];
        value.forEach((instructor) => {
            instructors.push({
                id: parseInt(instructor.id),
                firstName: instructor.firstName,
                lastName: instructor.lastName,
                username: instructor.username,
                role: instructor.role
            });
        });
        accessJson[key] = instructors;
    });

    console.log("JSON", accessJson);

    // add header
    baseAxiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
    await baseAxiosInstance.post( "/lessonplans/" + id + "/access", accessJson).catch((error) => {throw error});
}

// Get all instructors for lesson plan sharing
export async function getInstructors() {
    let instructors: Instructor[];
    await baseAxiosInstance.get("/instructors").then(function(response){
        instructors = response.data;
    }).catch((error) => {throw(error)});
    return instructors;
}

export async function getLessonPlans() {
    let lessonPlans: LessonPlan[];
    await baseAxiosInstance.get("/lessonplans").then(function(response){
        lessonPlans = response.data;
    }).catch((error) => {throw(error)});
    return lessonPlans;
}

export async function postLessonPlan(lessonPlan: LessonPlan) {
    let newPlan: LessonPlan;
    await baseAxiosInstance.post("/lessonplans", lessonPlan).then((response) => {
        newPlan = response.data;
    }).catch((error) => {throw error});
    return newPlan;
}

export async function putLessonPlan(id: number, lessonPlan: LessonPlan) {
    let newPlan: LessonPlan;
    await baseAxiosInstance.put("/lessonplans/" + id, lessonPlan).then((response) => {
        newPlan = response.data;
    }).catch((error) => {throw error});
    return newPlan;
}

export async function deleteLessonPlan(id: number) {
    await baseAxiosInstance.delete( "/lessonplans/" + id).catch((error) => {throw error});
}