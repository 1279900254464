import cloneDeep from "lodash.clonedeep";

export const ADD_LESSON_PLAN = -1;
export const DEFAULT_LESSON_PLAN_DATA = () => {
    return cloneDeep({
        id: null,
        name: ""
    });
}
export const DEFAULT_QUESTION_DATA = () => {
    return cloneDeep({
        id: null,
        lessonPlanId: null,
        number: null,
        prompt: "",
        screenshotRequired: false,
        title: "",
        type: "MULTIPLE_CHOICE"
    });
}
export const DEFAULT_QUESTION_CHOICE_DATA = () => {
    return cloneDeep({
        id: null,
        choiceText: "",
        correctChoice: false,
        questionId: null
    });
}
export const DEFAULT_QUESTION_AND_CHOICE_DATA = () => {
    return cloneDeep({
        question: DEFAULT_QUESTION_DATA(),
        choices: Array(4).fill(DEFAULT_QUESTION_CHOICE_DATA())
    });
}
export const LESSON_PLANS_URL = '/lessonplans';
export const QUESTIONS_URL = '/questions';
export const CHOICES_URL = '/choices';
