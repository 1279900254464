import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Box} from "@mui/material";
import React from "react";
import {ADD_LESSON_PLAN} from "../../utils/constants/lessonPlanConstants";

function LessonPlanTabControl( props: {lessonPlanId: number, showResponses: boolean, handleChange }) {
    if ( props.lessonPlanId === ADD_LESSON_PLAN ) {return null;}
    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Tabs value={props.showResponses} onChange={props.handleChange}>
                <Tab label="Details" value={false}/>
                <Tab label="Responses" value={true}/>
            </Tabs>
        </Box>
    )
}
export default LessonPlanTabControl;