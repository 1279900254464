import AddIcon from '@mui/icons-material/Add';
import { Box, Container, IconButton, List, ListItemButton, Typography } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import '../App.css';
import MoleculeDetails from "../components/molecule/MoleculeDetails";
import '../styles/Molecule.css';
import { getMolecules } from "../utils/helpers/moleculeHelper";
import { DEFAULT_MOLECULE, Molecule } from '../utils/types/molecule.types';

function ViewMolecules() {
    const [rows, setRows] = useState<Molecule[]>([]);

    // Edit Modal State
    const [molecule, setMolecule] = useState<Molecule>(DEFAULT_MOLECULE());

    function refreshMolecules(id: number) {
        getMolecules().then(function (molecules) {
            setRows(molecules);
            if (molecules.length > 0) {
                for (let i = 0; i < molecules.length; i++) {
                    if (molecules[i].id == id) {
                        setMolecule(molecules[i]);
                        return;
                    }
                }
                setMolecule(molecules[0]);
            } else {
                setMolecule(DEFAULT_MOLECULE());
            }
        })
    }

    useEffect(() => {refreshMolecules(0);}, []);

    return (
        <Box className="flexColumn" sx={{flex: '1'}}>
            <Container id="moleculePanel" sx={{display: 'flex', backgroundColor: 'white', height: '100%'}}>
                {/* Left Column */}
                <Box sx={{boxShadow: '1px 0px rgb(0 0 0 / 10%)', flexBasis:'250px'}}>
                    <Box className="moleculesLabel" sx={{ display: 'flex', justifyContent: 'space-between',
                        alignItems: 'center', p: 1, flexDirection: 'column' }}>
                        <Typography sx={{fontWeight: '500', fontSize: 20}}>Molecules</Typography>
                        <IconButton aria-label="add" onClick={() => setMolecule(DEFAULT_MOLECULE())}>
                            <AddIcon/>
                        </IconButton>
                    </Box>
                    <List>
                        {rows.map((rowMolecule, index) => (
                            <ListItemButton key={index} selected={molecule.id === rowMolecule.id} 
                                            sx={{ px: 2, py: 1}} onClick={() => setMolecule(rowMolecule)}>
                                <Typography sx={{ textAlign: 'center' }}>{rowMolecule.name}</Typography>
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
                {/* Right Column */}
                <MoleculeDetails molecule={molecule} updateMolecules={refreshMolecules}/>
            </Container>
        </Box>
    );
}

export default ViewMolecules;