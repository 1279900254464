import {
    ArToolkitContext,
    ArToolkitSource
} from '@ar-js-org/ar.js/three.js/build/ar-threex.js';
import { baseAxiosInstance } from '../../utils/services/BaseAxiosInstance.service';
import { MoleculeLibrary } from '../js/MoleculeLibrary';
import { EnvironmentalFactorLibrary } from "../js/EnvironmentalFactorLibrary";
import { ARMarkerNew } from '../js/ARMarker';
import { THREE } from '../../utils/constants/arViewConstants';
import { Molecule } from '../js/Molecule';
import { getMolecules } from '../../utils/helpers/moleculeHelper';
import {ARMarker} from "../../utils/types/util.types";
import {getARTagForMolecule, getARTagForFactorTrigger} from "../../utils/helpers/arTagHelper";
import { ARFactorTrigger, EnvironmentalFactor } from '../../utils/types/environmentalFactor.types';

export function ARApp() {
    // Settings variables
    this.visualControlMode = 'Ribbon';

    // THREE Js Rendering Variables
    this.renderer = null;
    this.scene = null;
    this.camera = null;

    // How strongly dragging should rotate the molecule.
    this.horizontalDragFactor = 5
    this.verticalDragFactor = 5

    // Scene Lighting
    this.directionalLight = null;
    this.ambientLight = null;

    // AR.js camera source and context
    this.arToolkitSource = null;
    this.arToolkitContext = null;

    // Tracked AR Markers
    this.arMarkers = [];

    this.activeTags = [];
    this.tagLibrary = [];
    this.timeSinceLastFactorTag = 0;
    this.timeSinceLastMoleculeTag = 0

    this.factorLibrary = new EnvironmentalFactorLibrary(this);

    // This is how molecules are added to scene: MoleculeLibrary takes "this" and gives it to every molecule contained within it.
    // Then calling setActive on a Molecule obtained from the MoleculeLibrary adds it to the scene.
    this.moleculeLibrary = new MoleculeLibrary(this);
    this.activeMolecule = null;

    this.init = async function (mountRef) {
        // Initialize Renderer And Scene
        this.initRenderer(mountRef);
        this.initScene();

        // Arrow overlay
        this.initArrowOverlay();
        
        this.initGestureDetection();

        // Initialize AR Variables
        this.initARSource();
        this.initARContext();

        // Load AR Markers
        this.tagLibrary = await this.getEnvironmentalTags();
        this.loadARMarkersAndMolecules();
    };

    this.run = function () {
        let _this = this;

        // run the rendering loop
        let lastTimeMsec = null;

        requestAnimationFrame(function animate(nowMsec) {
            // keep looping
            requestAnimationFrame(animate);

            // measure time
            lastTimeMsec = lastTimeMsec || nowMsec - 1000 / 60;
            let deltaMsec = Math.min(200, nowMsec - lastTimeMsec);
            lastTimeMsec = nowMsec;

            _this.update(deltaMsec / 1000);
            _this.render(deltaMsec / 1000);
        });
    };

    this.update = function (dt) {
        // Update the AR context if source is ready
        if (this.arToolkitSource.ready === true) {
            this.arToolkitContext.update(this.arToolkitSource.domElement);
        }

        if (this.activeMolecule != null) this.activeMolecule.update(dt);
        this.scanForMoleculeTags(dt);
        this.scanForEnvironmentTags(dt);
        this.factorLibrary.setActiveFactors(this.activeTags);
        this.factorLibrary.update();

    }

    this.render = function (dt) {
        // Render scene with renderer
        this.renderer.clear()
        this.renderer.render(this.scene, this.camera);
        this.renderer.clearDepth();
        this.renderer.render(this.overlayScene, this.overlayCamera);
    };

    this.scanForMoleculeTags = async function (dt) {
        for (let i = 0; i < this.arMarkers.length; i++) {
            if(this.arMarkers[i].object.visible) {
                this.moleculeLibrary.molecules.forEach((value: Molecule, key: String) => {
                    if (value.rootARMarker === this.arMarkers[i]) {
                        if (value === this.activeMolecule) {
                            this.activeMolecule.update(dt);
                        } else {
                            this.changeActiveMolecule(value);
                        }
                        this.timeSinceLastMoleculeTag = 0;
                    }
                });
            }
        }
        //Delay to remove active molecule
        this.timeSinceLastMoleculeTag += dt;
        if (this.timeSinceLastMoleculeTag > 0.5){
            if (this.activeMolecule) {
                this.activeMolecule.setActive(false); // avoids any null reference errors
            }
            this.activeMolecule = null;
        }
    }

    this.scanForEnvironmentTags = async function (dt) {
        let foundTags = [];
        for (let i = 0; i < this.arMarkers.length; i++) {
            if(this.arMarkers[i].object.visible) {

                for (let j = 0; j < this.tagLibrary.length; j++) {
                    if (this.tagLibrary[j].id === this.arMarkers[i].tagId) {
                         if (!this.factorLibrary.getFactor(this.tagLibrary[j].factor.id)) {
                             this.loadFactor(this.tagLibrary[j].factor, this.arMarkers[i]);
                        }

                        foundTags.push([this.tagLibrary[j].factor, this.tagLibrary[j].value]);
                        this.activeTags.push([this.tagLibrary[j].factor, this.tagLibrary[j].value]);
                        this.timeSinceLastFactorTag = 0;
                    }
                }
            }
        }
        //Delay to reduce flickering of tags
        this.timeSinceLastFactorTag += dt;
        if (this.activeTags != foundTags && this.timeSinceLastFactorTag > 0.5){
            this.activeTags = foundTags;
        } 
        this.checkActiveMoleculeConditions();
    }


    this.checkActiveMoleculeConditions = function() {
        if(this.activeMolecule != null){
            this.activeMolecule.factorStateTriggers.forEach(trigger => {
                if(trigger.isTrue(this.activeTags)){
                    this.activeMolecule.currentState = trigger.toState;
                    this.activeMolecule.setVisible(true);
                }
            });
        }
    }

    this.loadFactor = function(factor: EnvironmentalFactor, artkMarker) {
        // this.factorLibrary.removeSceneObjects();
        this.factorLibrary.load(factor, artkMarker);
      }

    //rewrite stop function that stop the streaming of the video after returning remove the possible risk --yang -->
    this.stop = function () {
        this.arToolkitSource.domElement.srcObject.getTracks().forEach(function (track) {
            track.stop();
        });
    }

    this.getARMarkerById = function (id) {
        // Return first marker in array with id
        for (let i = 0; i < this.arMarkers.length; i++) {
            let marker = this.arMarkers[i];
            if (marker.id === id) return marker;
        }

        // If not found return null
        return null;
    };

    // TODO: might be able to get this form environmentalfactorstaghelper.
    this.getEnvironmentalTags = () => {
        return baseAxiosInstance.get('/factor_tags').then(res => {
            return res.data.map((tag: ARFactorTrigger) => tag);
        });
    };

    this.changeActiveMolecule = function (molecule) {
        // Disable current active molecule
        if (this.activeMolecule != null) this.activeMolecule.setActive(false);

        // Set new active molecule and enable it
        this.activeMolecule = molecule;
        this.activeMolecule.setActive(true);
    };

    this.updateFactor = function (id, value) {
        if (this.activeMolecule != null) {
            this.activeMolecule.updateFactor(id, value);
        }
    };

    this.initRenderer = function (mountRef) {
        // init renderer
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
            devicePixelRatio: window.innerHeight / window.innerWidth
        });
        this.renderer.setClearColor(new THREE.Color('lightgrey'), 0);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.autoClear = false;

        mountRef.current.insertBefore(this.renderer.domElement, mountRef.current.firstChild);
    };

    this.initScene = function () {
        // init scene
        this.scene = new THREE.Scene();

        // Create a new camera using Three.js
        this.camera = new THREE.Camera();

        this.scene.add(this.camera);

        // Add Lighting to Scene
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        this.directionalLight.position.z = 1;
        this.scene.add(this.directionalLight);

        this.ambientLight = new THREE.AmbientLight(0x808080); // soft white light
        this.scene.add(this.ambientLight);
    };

    this.initARSource = function () {
        // Create local reference to this for anonymous functions
        let _this = this;

        this.arToolkitSource = new ArToolkitSource({
            // to read from the webcam
            sourceType: 'webcam',
            sourceWidth: window.innerWidth,
            sourceHeight: window.innerHeight,
            displayWidth: window.innerWidth,
            displayHeight: window.innerHeight
        });

        this.arToolkitSource.init(function onReady() {
            _this.onResize();
        });

        // handle window resize
        window.addEventListener('resize', function () {
            _this.onResize();
        });
    };

    this.initARContext = function () {
        var _this = this;
        // Create local reference to this for anonymous functions

        // create atToolkitContext
        _this.arToolkitContext = new ArToolkitContext({
            detectionMode: 'mono_and_matrix',
            matrixCodeType: '4x4_BCH_13_9_3',
            imageSmoothingEnabled : true
        });

        // initialize it
        _this.arToolkitContext.init(function onCompleted() {
            // copy projection matrix to camera
            _this.camera.projectionMatrix.copy(
                _this.arToolkitContext.getProjectionMatrix()
            );
        });
    };

    this.loadARMarkersAndMolecules = async function () {
        const molecules = await getMolecules();
        for (const molecule of molecules) {
            // AR Tag from the backend
            const marker = await getARTagForMolecule(molecule.id);
            // Special Marker object to give AR libraries (I think, IDK)
            const artk_marker = new ARMarkerNew(
                marker.id,
                null,
                marker.name,
                marker.id,
                this.arToolkitContext
            );
            this.arMarkers.push(artk_marker);
        }
        for (let i = 0; i < this.tagLibrary.length; i++) {
            // AR Tag from the backend
            const marker = await getARTagForFactorTrigger(this.tagLibrary[i].id);
            // Special Marker object to give AR libraries (I think, IDK)
            const artk_marker = new ARMarkerNew(
                marker.id,
                marker.arFactorTrigger,
                marker.name,
                marker.id,
                this.arToolkitContext
            );
            this.arMarkers.push(artk_marker);
        }
        this.loadStartingMolecules(-1);
    };

    this.loadStartingMolecules = function (selectedMoleculeIndex) {
        var _this = this;
        getMolecules().then(molecules => {
            for (let i = 0; i < molecules.length; i++) {
                let mjson = molecules[i];
                _this.moleculeLibrary.loadMolecule(
                    mjson.id,
                    mjson.name,
                    _this.visualControlMode
                );
            }
        });
    };

    this.initArrowOverlay = function () {
        // Create scene and camera
        this.overlayScene = new THREE.Scene();
        this.overlayCamera = new THREE.OrthographicCamera(window.innerWidth/-2,window.innerWidth/2,
            window.innerHeight/2,window.innerHeight/-2, 0,100);
        this.overlayScene.add(this.overlayCamera);

        // Add the image
        let map = THREE.ImageUtils.loadTexture(require("../../assets/Arrow_northeast.svg.png"));
        let spriteMaterial = new THREE.SpriteMaterial({map: map});
        this.arrow = new THREE.Sprite(spriteMaterial);
        this.overlayScene.add(this.arrow);

        // Set size and position for the arrow.
        let percentSize = 0.1 // The percent of the shortest dimension of the screen that the arrow's width and height will be.
        let lesserDim = Math.min(window.innerWidth, window.innerHeight)
        this.arrow.scale.set(lesserDim*percentSize,lesserDim*percentSize,1);
        this.arrow.position.set(lesserDim*percentSize/-2,lesserDim*percentSize/-2,1)
        this.arrow.castShadow = false;

    }

    this.fingers = [];
    this.handlePointerDown = (e: PointerEvent) => {
        this.fingers.push(e);
    };
    this.handlePointerUp = (e: PointerEvent) => {
        this.fingers = this.fingers.filter((finger: PointerEvent) => finger.pointerId !== e.pointerId);
    };
    this.handlePointerLeave = (e: PointerEvent) => {
        this.fingers = this.fingers.filter((finger: PointerEvent) => finger.pointerId !== e.pointerId);
    };
    this.handlePointerMove = (e: PointerEvent, domElement: HTMLElement) => {
        let idx = this.fingers.findIndex((finger: PointerEvent) => finger.pointerId === e.pointerId);

        if (idx === 0) {
            this.applyRotation(this.fingers[0], e, domElement);
            e.preventDefault();
        }

        if ([0, 1].includes(idx) && this.fingers.length > 1) {
            this.applyScale(this.fingers[idx], e, this.fingers[1 - idx]);
            e.preventDefault();
        }

        this.fingers[idx] = e;
    };
    this.initGestureDetection = function() {
        let domElement:HTMLCanvasElement = this.renderer.domElement;
        // Disables the webpage resizing from pinch. Only want the molecule to resize.
        domElement.style.touchAction = "none"
        domElement.onpointerdown = this.handlePointerDown;
        domElement.onpointerup = this.handlePointerUp;
        domElement.onpointerleave = this.handlePointerLeave;
        domElement.onpointermove = this.handlePointerMove;
    }

    this.applyRotation = (oldPointer:PointerEvent,newPointer:PointerEvent,canvas:HTMLCanvasElement) => {
        let changeX = canvas.offsetWidth==0?0:(newPointer.offsetX - oldPointer.offsetX)/canvas.offsetWidth*this.horizontalDragFactor
        let changeY = canvas.offsetHeight==0?0:(newPointer.offsetY - oldPointer.offsetY)/canvas.offsetHeight*this.verticalDragFactor
        // This is based on MoleculeRenderer's applyRotation.
        if(changeX == 0 && changeY == 0)
            return
        let axis = new THREE.Vector3(changeY,changeX,0).normalize()
        let angle = Math.sqrt(changeX**2+changeY**2)
        let rotQuaternion = new THREE.Quaternion().setFromAxisAngle(axis,angle)
        if(this.activeMolecule)
            this.activeMolecule.applyRotation(rotQuaternion)
    }

    this.applyScale = (oldPointer:PointerEvent,newPointer:PointerEvent,stationaryPointer:PointerEvent) => {
        let oldDist = getDistance(oldPointer,stationaryPointer);
        let newDist = getDistance(newPointer,stationaryPointer);
        if(this.activeMolecule)
            this.activeMolecule.applyScale(newDist/oldDist)
    }

    function getDistance(pointerA:PointerEvent,pointerB:PointerEvent) {
        let trueDist = Math.sqrt((pointerA.offsetX - pointerB.offsetX) ** 2 + (pointerA.offsetY - pointerB.offsetY) ** 2)
        return Math.max(1,trueDist)
    }

    this.resetArrowSize = function () {
        let percentSize = 0.1 // The percent of the shortest dimension of the screen that the arrow's width and height will be.
        let lesserDim = Math.min(window.innerWidth, window.innerHeight)
        this.arrow.scale.set(lesserDim*percentSize,lesserDim*percentSize,1);
        this.arrow.position.set(lesserDim*percentSize/-2,lesserDim*percentSize/-2,this.arrow.position.z)
    }

    // Z position will be -1 when inactive, 1 when active. -1 is outside the camera's view.
    this.toggleArrowOverlay = function () {
        this.arrow.position.z *= -1
    }

    this.onResize = function () {
        // Arrow logic
        this.resetArrowSize();
        this.overlayCamera.aspect = window.innerWidth / window.innerHeight;
        this.overlayCamera.left = window.innerWidth/-2
        this.overlayCamera.right = window.innerWidth/2
        this.overlayCamera.top = window.innerHeight/2
        this.overlayCamera.bottom = window.innerHeight/-2;
        this.overlayCamera.updateProjectionMatrix();

        // Call AR toolkits onResize
        this.arToolkitSource.onResizeElement();

        // Copy new size to renderer and AR context
        this.arToolkitSource.copyElementSizeTo(this.renderer.domElement);
        if (this.arToolkitContext.arController !== null) this.arToolkitSource.copyElementSizeTo(this.arToolkitContext.arController.canvas);
    };
}
