import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {QUESTIONS_URL} from "../constants/lessonPlanConstants";
import {RESPONSE_URL, RESPONSES_CSV_URL, RESPONSES_URL} from "../constants/responseConstants";
import {APP_URL} from "../constants/sessionConstants";
import {Question, Response} from "../types/lessonPlan.types";

export async function getLessonPlanResponses( lessonPlanId: number ) {
    let lessonPlanResponses: Response[];
    await baseAxiosInstance.get(RESPONSES_URL + '/' + lessonPlanId).then(function(response){
        lessonPlanResponses = response.data;
    }).catch((error) => {throw error});
    return lessonPlanResponses;
}

export async function getResponse( questionId: number, sessionId: string ) {
    let studentResponse: Response;
    await baseAxiosInstance.get(QUESTIONS_URL + APP_URL + '/' + questionId + RESPONSE_URL + '/' + sessionId).then((response) => {
        studentResponse = response.data;
    }).catch((error) => {
        throw error;
    });
    return studentResponse;
}

export async function submitResponses(questions: Question[], responses: Response[], sessionId: string) {
    let questionNumber = 0;
    let errorMessage = '';
    for (const question of questions) {
        // questionNumber++;
        // if(questionAndResponse.response.files !== undefined) {
        //     let formData = new FormData();
        //     for (let responseKey in questionAndResponse.response) {//forin 就是所有key便利一遍  forof就是所有的元素 //forin means all the keys are facilitated once. forof means all the elements.
        //         formData.append(responseKey, questionAndResponse.response[responseKey]);
        //     }
        //     console.log(formData);
        //
        //     formData.delete('files');
        //
        //     for (let file of questionAndResponse.response.files) {
        //         formData.append('files', file)
        //     }
        //
        //     await baseAxiosInstance.post(QUESTIONS_URL + APP_URL + '/' +"image" + '/' + sessionId,
        //         formData).catch((error) => {if (errorMessage !== '') errorMessage = "Couldn't Save Question " + questionNumber + " - " + error.message;});
        // }
        console.log("Submitting response for question", questionNumber, question.id, sessionId, responses[questionNumber]);
        responses[questionNumber].question = question;
        // remove the account from json
        delete responses[questionNumber].account;
        await baseAxiosInstance.post(QUESTIONS_URL + APP_URL + '/' + question.id + RESPONSE_URL + '/' + sessionId,
            responses[questionNumber]).catch((error) => {if (errorMessage !== '') errorMessage = "Couldn't Save Question " + questionNumber + " - " + error.message;});
        questionNumber++;
    }
    if ( errorMessage !== '' ) throw new Error(errorMessage);
}

export async function getDownloadResponses( lessonPlanId: number ) {
    let download: String;
    let csvStringArray: String[][];
    await baseAxiosInstance.get(RESPONSES_URL + RESPONSES_CSV_URL + '/' + lessonPlanId).then((response) => {
        download = response.data;
    }).catch((error) => {throw error});
    return download;
}