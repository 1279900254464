import { AREnvironmentalFactor } from '../js/AREnvironmentalFactor';

export function EnvironmentalFactorLibrary(app) {
    // Reference to app
    this.app = app;

    // Map of factor ids to environmental factor display objects
    this.factors = new Map();

    this.load = function (factor, arMarker) {
        // Create empty factor and add to library
        const arFactor = new AREnvironmentalFactor(this.app);
        arFactor.factorId = factor.id;
        arFactor.name = factor.name;
        arFactor.setARMarker(arMarker);
        arFactor.setImage(factor.image.image);
        this.factors.set(factor.id, arFactor);
        arFactor.addSceneObjects();
    };
    
    this.removeSceneObjects = function() {
      for(let factor of this.factors.values()) {
        factor.removeSceneObjects();
      }
    }

    this.update = function() {
      for(let factor of this.factors.values()) {
        factor.update();
      }
    }
    
    this.setActiveFactors = function(factorTags) {

      for(let factor of this.factors.values()) {
        factor.setVisible(false);

        // If the tag is present, make it visible
        for(let tag of factorTags) {
          if (!factor.factorId || factor.factorId !== tag[0].id) continue;

          factor.setVisible(true);
          break;
        }
      }
    }

    this.getFactor = function (id) {
        return this.factors.get(id);
    };
}
