import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {FACTOR_TAG_URL, IMAGE_URL} from "../constants/environmentalFactorTagConstants";
import { getEnvironmentalFactor } from "./environmentalFactorsHelper";
import { ARFactorTrigger } from "../types/environmentalFactor.types";

/**
 * READ ME.
 * 
 * Recently, we did a major overhaul of the backend and API.
 * However, we have not yet got around to front-end refactors.
 * Because of this, the methods here have been modified in such a way that new format responses from the API are converted to old formats.
 * Of course, this is a band-aid, and will need to be fixed.
 * 
 * Old stuff that has not been modified but is not currently needed has been commented out.
 */

export async function postEnvironmentalFactorTag( environmentalFactorTag: ARFactorTrigger ) {
    let newEnvironmentalFactorTag: ARFactorTrigger;
    await baseAxiosInstance.post("/factor_tags", environmentalFactorTag).then((response) => {
        newEnvironmentalFactorTag = response.data as ARFactorTrigger;
    }).catch((error) => {throw error});
    return newEnvironmentalFactorTag;
}

/**
 * Update ar factor trigger
 * @param environmentalFactorTagData environmental factor tag data
 * @param toDelete true if the tag should be deleted
 * TODO: implement deletion functionality
 */
export async function putEnvironmentalFactorTag( id: Number, environmentalFactorTag: ARFactorTrigger ) {
    let newEnvironmentalFactorTag: ARFactorTrigger;
    await baseAxiosInstance.put("/factor_tags/" + id, environmentalFactorTag).then((response) => {
        newEnvironmentalFactorTag = response.data as ARFactorTrigger;
    }).catch((error) => {throw error});
    return newEnvironmentalFactorTag;
}

export async function deleteEnvironmentalFactorTag( id: Number ) {
    let res;
    await baseAxiosInstance.delete("/factor_tags/" + id).then((response) => {
        res = response.data;
    }).catch((error) => {throw error});
    return res;
}

/**
 * Get tag image for ar factor trigger by id
 * @param environmentalFactorTagData AR factor trigger data
 * @returns tag image
 */
export async function getEnvironmentalFactorTagImage( environmentalFactorTag: ARFactorTrigger ) {
    const environmentalFactorTagData = environmentalFactorTag.factor;
    let imageData: string = '';
    await baseAxiosInstance.get("new/factor_tags" + '/' + environmentalFactorTagData.id + '/image').then((response) => {
        imageData = response.data;
    }).catch((error) => {throw error});
    return imageData;
}

/**
 * Get ar factor triggers
 * @returns ar factor triggers
 */
export async function getEnvironmentalFactorTags() {
    const res = (await baseAxiosInstance.get("factor_tags")).data;
    return res as ARFactorTrigger[];
}

/**
 * Get ar factor trigger by id
 * @param id ar factor trigger id
 * @returns ar factor trigger
 */
export async function getEnvironmentalFactorTag(id: number) {
    const res = (await baseAxiosInstance.get("new/factor_tags/" + id)).data;
    res.factorId = res.environmentalFactorId;
    return res;
}