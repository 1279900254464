import { baseAxiosInstance } from "../services/BaseAxiosInstance.service";
import { BaseModel, ColoredModel, Molecule } from "../types/molecule.types";
import { getEnvironmentalFactors } from "./environmentalFactorsHelper";
import LiteMol from "litemol";
import { THREE } from '../../utils/constants/arViewConstants';
import {getARTagForMolecule} from "./arTagHelper";

/**
 * READ ME.
 * 
 * Recently, we did a major overhaul of the backend and API.
 * However, we have not yet got around to front-end refactors.
 * Because of this, the methods here have been modified in such a way that new format responses from the API are converted to old formats.
 * Of course, this is a band-aid, and will need to be fixed.
 */

function indexArray(start: number, end: number) {
    let array = [];
    for (let i = start; i < end; i++) {
        array.push(i);
    }
    return array;
}

function addToParentModel(threeModel, chain, whole, baseModel: BaseModel) {
    // Center the molecule in the parent.
    var world = new THREE.Matrix4();
    world.makeScale(1, 1, 1);

    var c = new THREE.Matrix4();
    c.makeTranslation(-whole.centroid.x, -whole.centroid.y, -whole.centroid.z);

    var t = new THREE.Matrix4();
    t.makeTranslation(
        baseModel.transX,
        baseModel.transY,
        baseModel.transZ
    );

    var r = new THREE.Matrix4();
    var e = new THREE.Euler(
        baseModel.rotX,
        baseModel.rotY,
        baseModel.rotZ,
        'XYZ'
    );
    r.makeRotationFromEuler(e);

    var s = new THREE.Matrix4();
    s.makeScale(baseModel.scaleX, baseModel.scaleY, baseModel.scaleZ);

    world.multiply(t);
    world.multiply(r);
    world.multiply(s);
    world.multiply(c);
    chain.object.applyMatrix(world);

    // Add model to parent and set isLoaded
    threeModel.add(chain.object);
}

const defaultColors = ["#ff6666", "#66ff66", "#6666ff","#ff66ff"];

export function createMoleculeModel(coloredModel: ColoredModel, visualControlMode: string) {
    if (!coloredModel.baseModel.cifFile) return null;

    let threeModel = new THREE.Object3D();
    threeModel.visible = true;

    let splitBase64 = coloredModel.baseModel.cifFile.split(",", 2);
    let fileData = atob(splitBase64[splitBase64.length - 1]); // Can't find a non-deprecated option here

    // parse cif text
    let parseComp = LiteMol.Core.Formats.Molecule.SupportedFormats.mmCIF.parse(fileData);
    parseComp.run().then(out => {
        if (out.isError) { 
            // Error guard
            return;
        }
        const result = (out as LiteMol.Core.Formats.ParserSuccess<any>).result;

        let uniformTheme = LiteMol.Visualization.Theme.createUniform();

        // create the whole model for the molecule
        let wholeModelComp = LiteMol.Visualization.Molecule.Cartoons.Model.create(null, {
            atomIndices: result.models[0].data.atoms.indices,
            model: result.models[0],
            params: LiteMol.Visualization.Molecule.Cartoons.DefaultCartoonsModelParameters,
            props: null,
            queryContext: result.models[0].queryContext,
            theme: uniformTheme
        });

        
        wholeModelComp.run().then(wmodel => {
            let whole = wmodel;

            let data = result.models[0].data;
            // loop through all chains in the molecule
            for (let i = 0; i < data.chains.count; i++) {
                // Get color and theme for the chain
                let color = LiteMol.Visualization.Color.fromHexString(coloredModel.colorScheme.defaultColor);
                let chainColor;
                if(coloredModel.colorScheme.id === null)
                    chainColor =
                        {
                            id: i,
                            color:  defaultColors[i],
                            style: "BallsAndSticks",
                            description: null
                        }
                else
                    chainColor = coloredModel.colorScheme.chainColors[i];
                if (chainColor !== undefined) {
                    color = LiteMol.Visualization.Color.fromHexString(chainColor.color);
                }
                let colorMapping = LiteMol.Visualization.Theme.createPalleteIndexMapping((x) => { return 0 }, [color]);
                let theme = LiteMol.Visualization.Theme.createMapping(colorMapping);

                // Get the params of the molecule at the chain index
                let modelParams = {
                    atomIndices: indexArray(
                        data.chains.atomStartIndex[i],
                        data.chains.atomEndIndex[i]
                    ),
                    model: result.models[0],
                    props: null,
                    queryContext: result.models[0].queryContext,
                    theme: theme,
                    params: {}
                };

                let modelComp = null;
                // for space filling view, use VDW ball params
                if (visualControlMode === 'SpaceFilling') {
                    // params are based off of private method createVDWBallsParams() in LiteMol.Bootstrap.Visualization.Molecule
                    modelParams["params"] = {
                        bondRadius: 0,
                        hideBonds: true,
                        type: 'VDWBalls',
                        atomRadius:
                            LiteMol.Bootstrap.Utils.vdwRadiusFromElementSymbol(
                                modelParams.model
                            )
                    };
                    modelComp =
                        LiteMol.Visualization.Molecule.BallsAndSticks.Model.create(
                            null,
                            modelParams
                        );
                } else {
                    modelParams["params"] = LiteMol.Visualization.Molecule.Cartoons.DefaultCartoonsModelParameters;
                    modelComp =
                        LiteMol.Visualization.Molecule.Cartoons.Model.create(
                            null,
                            modelParams
                        );
                }

                modelComp.run().then(model => {
                    addToParentModel(threeModel, model, whole, coloredModel.baseModel);
                });
            }
        });
    });
    return threeModel;
}

/**
 * Create a new molecule in the database.
 * @param molecule molecule data
 */
export async function postMolecule(molecule: Molecule) {

    /**
     * No way for a user to set this at the moment.
     * As such we just set it here, though this is a bit of a band-aid.
     */
    for (const state of molecule.states) {
        for (let i = 0; i < defaultColors.length; i++) {
            state.coloredModel.colorScheme.chainColors.push({
                id: i,
                color: defaultColors[i],
                style: "BallsAndSticks",
                description: null,
            });
        }
    }

    let newMolecule: Molecule;

    await baseAxiosInstance.post("/molecules", molecule).then((response) => {
        newMolecule = response.data;
    }).catch((error) => {throw error});

    return newMolecule;
}

/**
 * Update a molecule in the database
 * @param moleculeId molecule id
 * @param moleculeStates molecule states
 * @param moleculeData molecule data
 */
export const putMolecule = async (id: number, molecule: Molecule) => {
    let newMolecule: Molecule;
    await baseAxiosInstance.put("/molecules/" + id, molecule).then((response) => {
        newMolecule = response.data as Molecule;
    }).catch((error) => {throw error});
    return newMolecule;
}

/**
 * Gets a molecule by id
 * @param id molecule id
 * @returns molecule data
 */
export const getMolecule = async (
    id: number
) => {
    const res = (await baseAxiosInstance.get("molecules/" + id)).data;
    // If a state does not load any ChainColors, give it the 4 default ones.id)).data;
    for (const state of [...res.states, res.defaultState]) {
        if(state.coloredModel.colorScheme.chainColors.length == 0)
            for (let i = 0; i < defaultColors.length; i++) {
                state.coloredModel.colorScheme.chainColors.push({
                    id: i,
                    chainIndex: i, // this isn't in the ChainColor object, but is needed in rendering
                    color: defaultColors[i],
                    style: "BallsAndSticks",
                    description: null
                });
            }
    }

    return res as Molecule;
}

/**
 * For some reason MoleculeController was using its own getMolecule logic so I just made another method for it here.
 * @param id molecule id
 * @returns data in the format that MoleculeController wants
 */
export const getMoleculeForMoleculeController = async (id) => {

    const molecule_res = await getMolecule(id);
    const marker_res = await getARTagForMolecule(id);
    const factors_res = await getEnvironmentalFactors();

    const molecule = {
        id: molecule_res.id,
        name: molecule_res.name,
        description: molecule_res.description,
        active: molecule_res.active,
        verticalOffset: molecule_res.verticalOffset,
        markerId: marker_res.id,
        defaultStateId: molecule_res.defaultState.id,
        factorStateTriggers: [],
        states: [],
        baseModels: [],
        colorSchemes: [],
        coloredModels: [],
        environmentalFactors: [],
        arFactorTriggers: []
    }

    for (const state of [...molecule_res.states, molecule_res.defaultState]) {
        molecule.states.push(state);
        for (const trigger of [...state.triggers]) {
            molecule.factorStateTriggers.push({
                ...trigger,
                toStateId: state.id
            });
        }

        /**
         * AR Factor Triggers are currently broken for some reason.
         * Perhaps I am implementing them incorrectly.
         * TODO: fix this
         */
        // molecule.arFactorTriggers.push(await getARTagForFactorTrigger(state.trigger.id));

        const { cifFile, cifPath } = await parseCifFileString(state.coloredModel.baseModel.cifFile.split(',')[1]);
        molecule.baseModels.push({
            ...state.coloredModel.baseModel,
            cifFile: cifFile,
            cifPath: cifPath
        });

        molecule.colorSchemes.push(state.coloredModel.colorScheme);
        // console.log(molecule.colorSchemes);
        molecule.coloredModels.push(state.coloredModel);
    }

    for (const factor of factors_res) {
        molecule.environmentalFactors.push(factor);
    }

    return molecule;
}

/**
 * Get a list of all molecules in the database
 * @return list of molecule data
 */
export const getMolecules = async () => {
    const molecules: Molecule[] = [];
    const res = (await baseAxiosInstance.get('/molecules')).data;
    for (const molecule of res) {
        molecules.push(molecule as Molecule);

        /**
         * For some reason, the default state is not being included in the molecule states array.
         * Until that is fixed, this hotfix is in place to deal with that.
         * TODO: remove this hotfix once the above issue is fixed
         */
        molecules[molecules.length - 1].states.push(molecules[molecules.length - 1].defaultState);

    }
    return molecules;
}

/**
 * Delete a molecule from the database.
 * @param moleculeId molecule id
 */
export async function deleteMolecule( moleculeId: number ) {
    await baseAxiosInstance.delete( "molecules/" + moleculeId).catch((error) => {throw error});
}

/**
 * Helper function for taking in a cifFile string and outputting a file and path objects.
 * @param cifFileString cifFile in string format
 * @return {{cifFile: File, cifPath: string}} cifFile and cifPath
 */
const parseCifFileString = async (cifFileString) => {
    const cifFile = new File([new Blob([atob(cifFileString)])], "name", {type: "chemical/x-cif"});
    const toDataURL = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const cifPath = await toDataURL(cifFile);
    return {
        cifFile: cifFile,
        cifPath: cifPath
    }
}